import axios from "axios";
import parse from "html-react-parser";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import noimage from "../../images/noprofile/noprofiel.png";
import MediaPopup from "../components/Common/MediaPopup";
const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {
    if (React.isValidElement(child)) {
      const style = child.props.style;
      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(";");
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });
  return styledHtml;
};

const ViewCoachOffcanvas = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [coachDetails, setCoachDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const Bio = coachDetails?.bio ? stripHtmlTags(coachDetails?.bio) : null;
  const [profileImg, setProfileImg] = useState();
  const [media, setMedia] = useState({});
  const [showMediaPopup, setShowMediaPopup] = useState(false);

  useImperativeHandle(ref, () => ({
    showCoachModal(coachId) {
      fetchCoachDetails(coachId);
      setShowModal(true);
    },
    hideCoachModal() {
      setShowModal(false);
    },
  }));

  const fetchCoachDetails = (coachId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_USER_PROFILE_API_URI}/getcoach/${coachId}`;
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(apiUrl, {
          headers: {
            authorization: ` ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("Complete response:", response.data.data);
          const img = response?.data?.data?.galleryData?.[0]?.fileLocation; // Ensure galleryData array is not empty
          console.log("Profile Image URL:", img); // Debugging the URL
          setProfileImg(img);
          const {
            userData,
            coachData,
            sportData,
            citiesData,
            stateData,
            galleryData,
            specialityData,
          } = response?.data?.data || {}; // Destructure userData, coachData, sportData, citiesData, and stateData
          if (
            userData &&
            coachData &&
            sportData &&
            citiesData &&
            stateData &&
            galleryData &&
            specialityData
          ) {
            // Find city and state based on stateId and id respectively

            setCoachDetails({
              userData, // Spread userData properties
              coachData, // Spread coachData properties
              sportData,
              citiesData, // Spread citiesData properties
              stateData, // Spread sportData properties
              galleryData,
              specialityData,
            });
          } else {
            console.error("Invalid response data format");
          }
        })
        .catch((error) => {
          console.error("Error fetching coach details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("Token not found in local storage");
      setLoading(false);
    }
  };

  const handleShowPopup = (type, src) => {
    setMedia({ mediaType: type, mediaSrc: src });
    setShowMediaPopup(true);
  };

  const handleClosePopup = () => {
    setMedia({ mediaType: "", mediaSrc: "" });
    setShowMediaPopup(false);
  };

  return (
    <>
      <MediaPopup
        show={showMediaPopup}
        handleClose={handleClosePopup}
        type={media.mediaType}
        src={media.mediaSrc}
      />

      <Offcanvas
        show={showModal}
        onHide={() => setShowModal(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        {loading ? (
          <p>
            <Spinner
              className="text-center"
              animation="border"
              variant="primary"
            />
          </p>
        ) : coachDetails ? (
          <>
            <div className="offcanvas-header profile-head-h">
              <h5 className="modal-title text-white" id="#gridSystemModal">
                {props.Title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <Offcanvas.Body>
              <div className="container">
                <form>
                  <div className="row d-flex justify-content-around">
                    <h3 className="profile-head">Profile Details</h3>

                    <div className="col-xl-12 mb-3 view-detail-table">
                      {profileImg ? (
                        <img
                          onClick={() => handleShowPopup("image", profileImg)}
                          src={profileImg}
                          alt={coachDetails?.userData?.firstName || "No Image"}
                        />
                      ) : (
                        <div>
                          <img src={noimage} alt="noimage" />{" "}
                        </div> // Display this text when no image is available
                      )}
                    </div>

                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">First Name:</span>
                        {coachDetails?.userData?.firstName || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Last Name:</span>
                        {coachDetails?.userData?.lastName || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Email:</span>
                        {coachDetails?.userData?.email || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Contact Number:</span>
                        {coachDetails.coachData.phone || ""}
                      </p>
                    </div>

                    <div className="col-xl-12 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Bio:</span>
                        {stripHtmlTags(coachDetails.coachData.bio || "")}
                      </p>
                    </div>
                    <h3 className="profile-head">Sport Details</h3>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">State:</span>
                        {coachDetails?.stateData &&
                          coachDetails.stateData.map(
                            (stateItem, stateIndex) => (
                              <span key={stateIndex}>
                                {stateIndex > 0 && ", "}
                                {stateItem?.name}
                              </span>
                            )
                          )}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">City:</span>
                        {console.log("citiesData:", coachDetails?.citiesData)}
                        {coachDetails?.citiesData &&
                          coachDetails.citiesData.map((cityItem, cityIndex) => (
                            <span key={cityIndex}>
                              {cityIndex > 0 && ", "}
                              {cityItem?.name}
                            </span>
                          ))}
                      </p>
                    </div>

                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Primary Sport(s):</span>
                        {coachDetails?.sportData
                          .map((sport) => sport.sportName)
                          .join(",") || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Coach Specialty:</span>
                        {coachDetails?.specialityData
                          .map((speciality) => speciality.specialityTitle)
                          .join(",") || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Age You Coach:</span>
                        {coachDetails?.coachData?.ageYouCoach || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Gender You Coach:</span>
                        {coachDetails.coachData.genderYouCoach || ""}
                      </p>
                    </div>

                    <div className="col-xl-12 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Achievements:</span>

                        {stripHtmlTags(
                          coachDetails.coachData.achievements || ""
                        )}
                      </p>
                    </div>
                    <h3 className="profile-head">Gallery Details</h3>

                    <div className="pl-25">
                      <div className="row mt-3">
                        {coachDetails?.galleryData &&
                          coachDetails?.galleryData.length > 0 &&
                          coachDetails?.galleryData.map((image, index) => (
                            <div
                              key={index}
                              className="col-xl-4 mb-3 p-2 d-grid"
                            >
                              <img
                                src={image.fileLocation}
                                alt={`Headshot Image ${index + 1}`}
                                style={{ width: "200px", height: "225px" }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                    <h3 className="profile-head">Social Media</h3>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">X Profile Link:</span>
                        {coachDetails?.coachData?.twitterLink || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Instagram Link:</span>
                        {coachDetails?.coachData?.instagramLink || ""}
                      </p>
                    </div>
                    <div className="col-xl-12 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Website Link:</span>
                        {coachDetails?.coachData?.websiteLink || ""}
                      </p>
                    </div>

                    <h3 className="profile-head">Profile Status</h3>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Approval Status:</span>
                        {coachDetails?.coachData?.isApprove || ""}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">Active Status:</span>
                        {coachDetails?.coachData?.isActive
                          ? "Active"
                          : "Inactive" || ""}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </Offcanvas.Body>
          </>
        ) : (
          <p>No data available for the coach</p>
        )}
      </Offcanvas>
    </>
  );
});

export default ViewCoachOffcanvas;
