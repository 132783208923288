import axios from "axios";

export default class SportsManagerService {
	static getSpecialitiesBySport = async (sportsId) => {
    		const token = localStorage.getItem("token");
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_SPORTS_API_URI}/getspecialitybysport?sportId=${sportsId}`,
				{
					headers: {
						Accept: "application/json, text/plain, */*",
						"Content-Type": "multipart/form-data",
						authorization: `${token}`,
					},
				}
			);

			return response;
		} catch (error) {
			console.error("Error fetching speciality details:", error);
		}
	};
}
