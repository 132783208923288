import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../layouts/nav/index";
import MainPagetitle from "../../layouts/MainPagetitle";
import AthleteOffcanvas from "../../constant/AthleteOffcanvas";
import ViewAthleteOffcanvas from "../../constant/ViewAthleteOffcanvas";
import axios from 'axios';

import Select from 'react-select';
import Collapse from 'react-bootstrap/Collapse';

const profile = [
  { value: "2", label: "Athlete" },
  { value: "3", label: "Club" },
  { value: "4", label: "Coach" },
  { value: "5", label: "other" },
];

const Contact = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const sort = 10;
  const activePag = useRef(0);
  const [test, setTest] = useState(0);

  useEffect(() => {
    // Fetch data when component mounts
    fetchData();
  }, []);

  const fetchData = () => {
    // Retrieve token from local storage
    const token = localStorage.getItem('token');

    // Set up Axios with token in request headers
    axios.get(`${process.env.REACT_APP_CONTENT_API_URI}/getcontacts`, {
      headers: {
        'authorization': `${token}`
      }
    })
    .then(response => {
      // Handle success
      setTableData(response.data.data);
      setLoading(false);
    })
    .catch(error => {
      // Handle error
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  };

  // Pagination setup
  const chageData = (first, second) => {
    for (let i = 0; i < tableData.length; ++i) {
      if (i >= first && i < second) {
        document.querySelectorAll("#employee-tbl_wrapper tbody tr")[i].classList.remove("d-none");
      } else {
        document.querySelectorAll("#employee-tbl_wrapper tbody tr")[i].classList.add("d-none");
      }
    }
  };

  useEffect(() => {
    setTest(document.querySelectorAll("#employee-tbl_wrapper tbody tr").length);
  }, [tableData]);

  useEffect(() => {
    activePag.current === 0 && chageData(0, sort);
  }, [tableData]);

  let paggination = Array(Math.ceil(tableData.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    setTest(i);
  };

  const employe = useRef();
  const employee = useRef();
  const viewathlete = useRef();

  return (
    <>
      <MainPagetitle
        mainTitle="Contact"
        pageTitle={"Contact"}
        parentTitle={"Home"}
      />
      <div className="">
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary"></div>
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                    {loading ? (
                      <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                        <tbody>
                          <tr>
                            <td colSpan="6">Loading...</td>
                          </tr>
                        </tbody>
                      </table>
                    ) : tableData.length > 0 ? (
                      <table id="employee-tbl_wrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                            <th className="text-center">Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.firstname}</td>
                              <td>{item.lastname}</td>
                              <td>{item.phone}</td>
                              <td>{item.email}</td>
                              <td className="text-center">{item.message}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                        <tbody>
                          <tr>
                            <td colSpan="6">No data available</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {activePag.current * sort + 1} to{" "}
                        {tableData.length > (activePag.current + 1) * sort
                          ? (activePag.current + 1) * sort
                          : tableData.length}{" "}
                        of {tableData.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={() =>
                            activePag.current > 0 && onClick(activePag.current - 1)
                          }
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {paggination.map((number, i) => (
                            <Link
                              key={i}
                              to="#"
                              className={`paginate_button ${activePag.current === i ? "current" : ""}`}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={() =>
                            activePag.current + 1 < paggination.length &&
                            onClick(activePag.current + 1)
                          }
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AthleteOffcanvas ref={employe} Title="Add Athlete" />
      <AthleteOffcanvas ref={employee} Title="Update Athlete" />
      <ViewAthleteOffcanvas ref={viewathlete} Title="View Athlete Details" />
    </>
  );
};

export default Contact;
