import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import TextEditor from "../components/Common/TextEditor";

const EditSubscriptionoffcanvas = forwardRef((props, ref) => {
  const [editSubscription, setEditSubscription] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true); // Control loading state

  const [formData, setFormData] = useState({
    subscriptionName: "",
    description: "",
    subscriptionAmount: "",
    subscriptionLimit: "",
    subscribtionStatus: "",
    processingFee: "",
    convenienceFee: "",
    serviceFee: "",
    subscriptionMonth: "",
    roleId: "",
  });

  useEffect(() => {
    if (subscriptionData) {
      setFormData(subscriptionData); // Set form data with subscription data
    }
  }, [subscriptionData]);

  useImperativeHandle(ref, () => ({
    async showEmployeModal(id) {
      console.log("subscription ID:", id);
      setEditSubscription(true);
      setSubscriptionData(null); // Reset subscription data
      await fetchSubscriptionById(id);
    },
  }));

  const validationSchema = Yup.object().shape({
    subscriptionName: Yup.string().required("Subscription name is required"),
    // .matches(/^[a-zA-Z0-9]*$/, 'Subscription name must be alphanumeric'),
    description: Yup.string()
      .required("Description is required")
      .max(500, "Description must not exceed 500 words"),
    subscriptionAmount: Yup.number()
      .required("Subscription amount is required")
      .positive("Amount must be a positive number"),
    subscriptionLimit: Yup.string().required(
        "Subscription Limit is required"
      ),
    subscribtionStatus: Yup.string().required(
      "Subscription Status is required"
    ),
    roleId: Yup.string().required("Profile is required"),
    processingFee: Yup.number()
      .typeError("Processing Fee must be a decimal number")
      .required("Processing Fee is required")
      .min(0, "Tax must be greater than or equal to 0")
      .max(100, "Tax must be less than or equal to 100")
      .test(
        "decimal-places",
        "Tax must have up to 3 decimal places",
        (value) => value === undefined || /^\d+(\.\d{1,3})?$/.test(value)
      ),
    convenienceFee: Yup.number()
      .typeError("Convenience Fee must be a decimal number")
      .required("Convenience Fee is required")
      .min(0, "Tax must be greater than or equal to 0")
      .max(100, "Tax must be less than or equal to 100")
      .test(
        "decimal-places",
        "Tax must have up to 3 decimal places",
        (value) => value === undefined || /^\d+(\.\d{1,3})?$/.test(value)
      ),
    serviceFee: Yup.number()
      .typeError("Service Fee must be a decimal number")
      .required("Service Fee is required")
      .min(0, "Tax must be greater than or equal to 0")
      .max(100, "Tax must be less than or equal to 100")
      .test(
        "decimal-places",
        "Tax must have up to 3 decimal places",
        (value) => value === undefined || /^\d+(\.\d{1,3})?$/.test(value)
      ),
    subscriptionMonth: Yup.string()
      .required("subscriptionMonth is required")
      .matches(/^\d{1,2}$/, "subscriptionMonth should be of two digits"),
  });

  const fetchSubscriptionById = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_PAYMENT_API_URI}/getsubscription/${id}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setSubscriptionData(response.data.data || {});
      setLoading(false);
    } catch (error) {
      console.error("Error fetching subscription data:", error);
      toast.error("Failed to fetch subscription data!");
      setSubscriptionData({});
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchSports = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_SPORTS_API_URI}/getallsports`,
          {
            headers: {
              authorization: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setSports(response.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };

    fetchSports();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_API_URI}/updatesubscription`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `${token}`,
          },
        }
      );
      console.log("Response:", response.data);
      props.onsubscriptionUpdated();
      toast.success("Subscription updated successfully!");
      setEditSubscription(false);
    } catch (error) {
      console.error("Error updating subscription:", error);
      toast.error("Failed to update subscription!");
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      ></div>
    );
  }

  return (
    <>
      <Offcanvas
        show={editSubscription}
        onHide={() => setEditSubscription(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h5 className="modal-title text-white" id="#gridSystemModal">
            Edit Subscription
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setEditSubscription(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row">
                    {/* Subscription Name */}
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <label htmlFor="subscriptionName" className="form-label">
                        Subscription Name
                      </label>
                      <Field
                        name="subscriptionName"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="subscriptionName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Description using CKEditor */}
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      {/* <label htmlFor="description" className="form-label">
                        Description
                      </label> */}
                      {/* <CKEditor
                        editor={ClassicEditor}
                        data={formData.description || ""} // Safe navigation and default
                        onChange={(event, editor) => {
                          setFieldValue("description", editor.getData());
                        }}
                      /> */}
                      <TextEditor
                        content={formData.description}
                        setContent={(newContent) =>
                          setFieldValue("description", newContent)
                        }
                        title={'Description'}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Subscription Amount */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label
                        htmlFor="subscriptionAmount"
                        className="form-label"
                      >
                        Subscription Amount
                      </label>
                      <Field
                        name="subscriptionAmount"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="subscriptionAmount"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Subscription Limit */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="subscriptionLimit" className="form-label">
                        Subscription Limit
                      </label>
                      <Field
                        name="subscriptionLimit"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="subscriptionLimit"
                        component="div"
                        className
                      />
                    </div>
                    {/* Subscription Month */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="subscriptionMonth" className="form-label">
                        Duration (Month) <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="subscriptionMonth"
                        name="subscriptionMonth"
                        placeholder="subscriptionMonth"
                      />
                      <ErrorMessage
                        name="subscriptionMonth"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Profile */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="roleId" className="form-label">
                        Profile <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="default-select form-control"
                        id="roleId"
                        name="roleId"
                      >
                        <option value="" disabled>
                          Select Profile
                        </option>
                        <option value="2">Athlete</option>
                        <option value="3">Club and Academy</option>
                      </Field>
                      <ErrorMessage
                        name="roleId"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Subscription Status */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label
                        htmlFor="subscribtionStatus"
                        className="form-label"
                      >
                        Subscription Status
                      </label>
                      <Field
                        name="subscribtionStatus"
                        as="select"
                        className="default-select form-control"
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Field>
                      <ErrorMessage
                        name="subscribtionStatus"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Processing Tax */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="processingFee" className="form-label">
                        Processing Fee
                      </label>
                      <Field
                        name="processingFee"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="processingFee"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Convenience Tax */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="convenienceFee" className="form-label">
                        Convenience Fee
                      </label>
                      <Field
                        name="convenienceFee"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="convenienceFee"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Service Tax */}
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="serviceFee" className="form-label">
                        Service Fee
                      </label>
                      <Field
                        name="serviceFee"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="serviceFee"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary me-1"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditSubscription(false)}
                    className="btn btn-danger light ms-1"
                  >
                    Cancel
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default EditSubscriptionoffcanvas;
