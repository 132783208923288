import React, { useState, useEffect } from "react";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import AllProjectDonutChart from './AllProjectDonutChart';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const CardWidget = () => {
  const [open] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sport, setSport] = useState([]);
  const [state, setState] = useState([]);
  const [filters, setFilters] = useState({
    sportsId: '',
    stateId: '',
    asOfDate: '',
  });

  const initialProfileState = {
    active: 0,
    inactive: 0,
    expired: 0,
  };
  const [overallProfiles, setOverallProfiles] = useState(initialProfileState);
  const [athleteProfiles, setAthleteProfiles] = useState(initialProfileState);
  const [academieProfiles, setAcademieProfiles] = useState(initialProfileState);
  const [coachProfiles, setCoachProfiles] = useState({
    active: 0,
    inactive: 0,
  });

  useEffect(() => {
    fetchSports();
    fetchStates();
    fetchProfileData(filters);
  }, []);

  useEffect(() => {
    fetchProfileData(filters);
  }, [filters]);


  const fetchSports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setSport(response.data.data);
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setState(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilters((prevFilters) => ({
      ...prevFilters,
      asOfDate: date ? date.toISOString().split('T')[0] : '',
    }));
  };

  const handleFilterChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  const fetchProfileData = async (filters) => {
    try {
      const { sportsId, stateId, asOfDate } = filters;
      const token = localStorage.getItem('token');

      const [athleteResponse, academieResponse, coachResponse, overallResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_REPORTS_API_URI}/athleteprofiles`, {
          params: { sportsId, state: stateId, asOfDate },
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data',
            'authorization': `${token}`,
          }
        }),
        axios.get(`${process.env.REACT_APP_REPORTS_API_URI}/academieprofiles`, {
          params: { sportsId, state: stateId, asOfDate },
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data',
            'authorization': `${token}`,
          }
        }),
        axios.get(`${process.env.REACT_APP_REPORTS_API_URI}/coachprofiles`, {
          params: { sportsId, state: stateId, asOfDate },
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data',
            'authorization': `${token}`,
          }
        }),
        axios.get(`${process.env.REACT_APP_REPORTS_API_URI}/overallprofiles`, {
          params: { sportsId, state: stateId, asOfDate },
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'multipart/form-data',
            'authorization': `${token}`,
          }
        })
      ]);

      // Update state with the fetched data
      setAthleteProfiles({
        active: athleteResponse.data.athleteActiveProfile,
        inactive: athleteResponse.data.athleteInActiveProfile,
        expired: athleteResponse.data.athleteExpiredProfile,
      });

      setAcademieProfiles({
        active: academieResponse.data.academieActiveProfile,
        inactive: academieResponse.data.academieInActiveProfile,
        expired: academieResponse.data.academieExpiredProfile,
      });

      setCoachProfiles({
        active: coachResponse.data.coachActiveProfile,
        inactive: coachResponse.data.coachInActiveProfile,
      });
      setOverallProfiles({
        active: overallResponse.data.ActiveProfileCount,
        inactive: overallResponse.data.InActiveProfileCount,
        expired: overallResponse.data.ExpiredProfileCount,
      });


    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };


  const resetFilters = () => {
    setSelectedDate(null);
    const emptyFilters = {
      sportsId: '',
      stateId: '',
      asOfDate: '',
    };
    setFilters(emptyFilters);
    setSport([]);
    setState([]);
    fetchProfileData(emptyFilters);
    fetchSports();
    fetchStates();
  };
  const totalProfiles = overallProfiles.active + overallProfiles.inactive + overallProfiles.expired;
  const totalAthleteProfiles = athleteProfiles.active + athleteProfiles.inactive + athleteProfiles.expired;
  const totalAcademieProfiles = academieProfiles.active + academieProfiles.inactive + academieProfiles.expired;
  const totalCoachProfiles = coachProfiles.active + coachProfiles.inactive;

  const getProgressWidth = (count, total) => {
    return total ? (count / total) * 100 : 0;
  };
  return (
    
    <div className="row bg-white dash-card-1 justify-content-center" style={{ display: "flex", flexWrap: "wrap" }}>
      <Collapse in={open}>
        <div className="cm-content-body form excerpt">
          <div className="card-body">
            <div className="row filter-row">
              <div className="col-xl-4 col-sm-6 py-1">
                <label>Select Sport</label>
                <Select
                  key={sport} // Use key to force re-render
                  isSearchable={false}
                  options={sport.map(s => ({ label: s.sportName, value: s.id }))}
                  className="custom-react-select"
                  menuPortalTarget={document.body}
                  name="sportsId"
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-xl-3 col-sm-6 py-1">
                <label>Select State</label>
                <Select
                  key={state} // Use key to force re-render
                  isSearchable={false}
                  options={state.map(s => ({ label: s.name, value: s.id }))}
                  className="custom-react-select"
                  menuPortalTarget={document.body}
                  name="stateId"
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-xl-3 col-sm-6 py-1 d-flex flex-column">
                <label>As of Date</label>
                <DatePicker
                  key={selectedDate} // Use key to force re-render
                  className="form-control mb-xl-0 mb-3"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="MM-DD-YYYY"
                  dateFormat="MM-dd-yyyy"
                />
              </div>
              <div className="col-xl-1 col-sm-6 py-1 d-flex align-items-end">
                <button
                  className="btn btn-primary me-2"
                  title="Click here to Apply Filter"
                  type="button"
                  onClick={() => fetchProfileData(filters)}
                >
                  <i className="bi bi-filter"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  title="Click here to Reset Filters"
                  type="button"
                  onClick={resetFilters}
                >
                  <i className="bi bi-x-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <div className="col-xl-3  col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-primary" style={{ height: "100%" }}>
          <div className="card-body  p-4">
            <div className="media">
              <span className="me-3">
                <i className="la la-users"></i>
              </span>
              <div className="media-body text-white">
                <p className="mb-1">Total Number of Profiles</p>
                <h3 className="text-white">{totalProfiles}</h3>
                {/* <div className="progress mb-2 bg-secondary">
                  <div
                    className="progress-bar progress-animated bg-white"
                    style={{ width: `${getProgressWidth(totalProfiles)}%` }}
                  ></div>
                </div> */}
                <small>Onboarded</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Athlete Profiles */}
      <div className="col-xl-3  col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-warning" style={{ height: "100%" }}>
          <div className="card-body  p-4">
            <div className="media">
              <span className="me-3">
                <i className="la la-user"></i>
              </span>
              <div className="media-body text-white">
                <p className="mb-1">Total Athlete Profiles</p>
                <h3 className="text-white">{totalAthleteProfiles}</h3>
                <div className="progress mb-2 bg-secondary">
                  <div
                    className="progress-bar progress-animated bg-white"
                    style={{ width: `${getProgressWidth(totalAthleteProfiles, totalProfiles)}%` }}
                  ></div>
                </div>
                <small>Onboarded</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Academie Profiles */}
      <div className="col-xl-3  col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-primary" style={{ height: "100%" }}>
          <div className="card-body  p-4">
            <div className="media">
              <span className="me-3">
                <i className="la la-building"></i>
              </span>
              <div className="media-body text-white">
                <p className="mb-1">Total Academie Profiles</p>
                <h3 className="text-white">{totalAcademieProfiles}</h3>
                <div className="progress mb-2 bg-secondary">
                  <div
                    className="progress-bar progress-animated bg-white"
                    style={{ width: `${getProgressWidth(totalAcademieProfiles, totalProfiles)}%` }}
                  ></div>
                </div>
                <small>Onboarded</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Coach Profiles */}
      <div className="col-xl-3  col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-warning" style={{ height: "100%" }}>
          <div className="card-body  p-4">
            <div className="media">
              <span className="me-3">
                <i className="la la-chalkboard-teacher"></i>
              </span>
              <div className="media-body text-white">
                <p className="mb-1">Total Coach Profiles</p>
                <h3 className="text-white">{totalCoachProfiles}</h3>
                <div className="progress mb-2 bg-secondary">
                  <div
                    className="progress-bar progress-animated bg-white"
                    style={{ width: `${getProgressWidth(totalCoachProfiles, totalProfiles)}%` }}
                  ></div>
                </div>
                <small>Onboarded</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6  col-lg-6 col-sm-6  " style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-primary" style={{ height: "100%" }}>

          <div className="card-body p-4">
            <h2 className="mb-1 text-white text-center">Overall Profiles  </h2>

            <div className="media  text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className=" bi bi-person-check "></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile Active</h4>
                <h3 className="text-white">{overallProfiles.active}</h3>
              </div>

            </div>
            <div className="media  text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i class="bi bi-person-dash"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white"> Number Of Profile InActive</h4>
                <h3 className="text-white">{overallProfiles.inactive}</h3>
              </div>
            </div>
            <div className="media  text-white ">
              <span className="me-3 mb-3">
                <i class="bi bi-person-x"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white"> Number Of Profile Expired</h4>
                <h3 className="text-white">{overallProfiles.expired}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Athlete Profile Widget */}
      <div className="col-xl-6 col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-warning" style={{ height: "100%" }}>
          <div className="card-body p-4">
            <h2 className="mb-1 text-white text-center">Athlete Profile</h2>
            <div className="media text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className="bi bi-person-check"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile Active</h4>
                <h3 className="text-white">{athleteProfiles.active}</h3>
              </div>
            </div>
            <div className="media text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className="bi bi-person-dash"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile InActive</h4>
                <h3 className="text-white">{athleteProfiles.inactive}</h3>
              </div>
            </div>
            <div className="media text-white">
              <span className="me-3 mb-3">
                <i className="bi bi-person-x"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile Expired</h4>
                <h3 className="text-white">{athleteProfiles.expired}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Academie Profile Widget */}
      <div className="col-xl-6 col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-warning" style={{ height: "100%" }}>
          <div className="card-body p-4">
            <h2 className="mb-1 text-white text-center">Club/Academy Profile</h2>
            <div className="media text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className="bi bi-person-check"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile Active</h4>
                <h3 className="text-white">{academieProfiles.active}</h3>
              </div>
            </div>
            <div className="media text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className="bi bi-person-dash"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile InActive</h4>
                <h3 className="text-white">{academieProfiles.inactive}</h3>
              </div>
            </div>
            <div className="media text-white">
              <span className="me-3 mb-3">
                <i className="bi bi-person-x"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile Expired</h4>
                <h3 className="text-white">{academieProfiles.expired}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Coach Profile Widget */}
      <div className="col-xl-6 col-lg-6 col-sm-6" style={{ marginBottom: "20px" }}>
        <div className="widget-stat card bg-primary" style={{ height: "100%" }}>
          <div className="card-body p-4">
            <h2 className="mb-1 text-white text-center">Coach Profile</h2>
            <div className="media text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className="bi bi-person-check"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile Active</h4>
                <h3 className="text-white">{coachProfiles.active}</h3>
              </div>
            </div>
            <div className="media text-white mb-3 bb-2">
              <span className="me-3 mb-3">
                <i className="bi bi-person-dash"></i>
              </span>
              <div className="media-body text-white d-flex justify-content-between">
                <h4 className="text-white">Number Of Profile InActive</h4>
                <h3 className="text-white">{coachProfiles.inactive}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWidget;
