import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const EditSpecialityOffcanvas = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    const [addEmployee, setAddEmployee] = useState(false);
    const [specialityDetails, setSpecialityDetails] = useState(null);
    const [sports, setSports] = useState([]);
    const [specialityId, setSpecialityId] = useState([]);
    useImperativeHandle(ref, () => ({
        showEmployeModal(sportId) {
            setAddEmployee(true);
            fetchSpecialityDetails(sportId);
        },
    }));

    useEffect(() => {
        const fetchSports = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
                    headers: {
                        'authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                setSports(response.data);
            } catch (error) {
                console.error('Error fetching sports data:', error);
            }
        };

        fetchSports();
    }, []);

    useEffect(() => {
        if (specialityDetails) {

        }
    }, [specialityDetails]);

    const validationSchema = Yup.object().shape({
        specialityTitle: Yup.string()
            .required('Speciality Title is required')
            .max(50, 'Speciality Title must be within 50 characters'),
        sportsId: Yup.array()
             .of(Yup.string().required('Sport is required'))
             .min(1, 'At least one sport must be selected')
    });

    const fetchSpecialityDetails = async (specialityId) => {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_SPORTS_API_URI}/getspeciality/${specialityId}`;
        const token = localStorage.getItem('token');

        if (token) {
            try {
                const response = await axios.get(apiUrl, {
                    headers: {
                        'authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                setSpecialityDetails(response.data.data);
                setSpecialityId(response.data?.data?.specialityData?.id);

                console.log(specialityId);
                console.log(specialityDetails);
            } catch (error) {
                console.error('Error fetching speciality details:', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Token not found in local storage');
            setLoading(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        const token = localStorage.getItem('token');

        try {
            const formData = {
                id: specialityId,
                specialityTitle: values.specialityTitle,
                sportId: values.sportId,
                sportsId: values.sportsId
            };
            console.log(formData);
            const response = await axios.post(`${process.env.REACT_APP_SPORTS_API_URI}/editspeciality`, formData, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);
            toast.success("Speciality details updated successfully");
            setAddEmployee(false);
            props.onsportUpdated();
        } catch (error) {
            console.error('Error updating speciality:', error);
            toast.error("Failed to update speciality details");
        }

        setSubmitting(false);
    };

    return (
        <Offcanvas show={addEmployee} onHide={() => setAddEmployee(false)} className="offcanvas-end customeoff" placement='end'>
            <div className="offcanvas-header profile-head-h">
                <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
                <button type="button" className="btn-close" onClick={() => setAddEmployee(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="offcanvas-body">
                <div className="container-fluid">
                    <div>
                        {!loading && specialityDetails && (
                            <Formik
                                initialValues={{
                                    specialityTitle: specialityDetails ? specialityDetails.specialityData?.specialityTitle : '',
                                    sportId: specialityDetails ? specialityDetails.specialityData?.sportId : '',
                                    sportsId: specialityDetails ? specialityDetails?.sportData.map(sport =>( sport.sportId)) : []
                                }}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting,setFieldValue, values }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="specialityTitle" className="form-label">Speciality Title <span className="text-danger">*</span></label>
                                                <Field type="text" className="form-control" id="specialityTitle" name="specialityTitle" />
                                                <ErrorMessage name="specialityTitle" component="div" className="text-danger" />
                                            </div>
                                            <label htmlFor="sportsId" className="form-label">Sports <span className="text-danger">*</span></label>
                                            <Select
                                                name="sportsId"
                                                options={sports.data.map(sport => ({ value: sport.id, label: sport.sportName }))}
                                                classNamePrefix="react-select"
                                                isMulti
                                                id="sportsId"
                                                onChange={option => {

                                                    setFieldValue('sportsId', option ? option.map(opt => opt.value) : []);
                                                }}
                                                value={values.sportsId ? values.sportsId.map(id => ({ value: id, label: sports.data.find(sport => sport.id === id)?.sportName })) : []}
                                                /> 
                                            <ErrorMessage name="sportId" component="div" className="text-danger" />
                                        </div>
                                        <div>
                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary me-1">Update</button>
                                            <Link to="#" onClick={() => setAddEmployee(false)} className="btn btn-danger light ms-1">Cancel</Link>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </div>
                </div>
            </div>
        </Offcanvas>
    );
});

export default EditSpecialityOffcanvas;
