import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import axios from "axios";

/// Images
import avatar1 from '../../images/avatar/1.jpg';
import avatar2 from '../../images/avatar/2.jpg';
import MsgBox from '../components/chatBox/MsgBox';

const Message = forwardRef(({ PerfectScrollbar, toggleChatBox, toggleTab, props }, ref) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [athleteDetails, setAthleteDetails] = useState([]);
    const [openMsg, setOpenMsg] = useState(false);
    const [senderId, setSenderId] = useState(null);
    const [receiverId, setReceiverId] = useState(null);

    useImperativeHandle(ref, () => ({
        showEmployeModal(id) {
            fetchAthleteMessages(id);
            setShowModal(true);
        },
        hideEmployeModal() {
            setShowModal(false);
        },
    }));

    const fetchAthleteMessages = (senderId) => {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_NOTIFICATION_API_URI}/messages/connecteduser/${senderId}`;
        const token = localStorage.getItem("token");

        if (token) {
            axios
                .get(apiUrl, {
                    headers: {
                        authorization: ` ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setAthleteDetails(response.data);
                    setSenderId(senderId); // Set senderId here
                })
                .catch((error) => {
                    console.error("Error fetching athlete details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error("Token not found in local storage");
            setLoading(false);
        }
    };

    const handleUserClick = (receiverId) => {
        setReceiverId(receiverId);
        setOpenMsg(true);
    };

    return (
        <>
            <Offcanvas style={{ width: "600px", backgroundColor: "#fff" }} show={showModal} onHide={() => setShowModal(false)} placement="end">
                <div className="chatbox active" style={{ width: "600px", backgroundColor: "#fff" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title> Messages</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div
                            show={showModal} onHide={() => setShowModal(false)}
                            id='chat'
                            role='tabpanel'
                        >
                            <div className={`card mb-sm-3 mb-md-0 contacts_card dlab-chat-user-box ${openMsg ? 'd-none' : ''}`}>
                                <div className={`card-body contacts_body p-0 dz-scroll ${toggleChatBox ? '' : ''}`} id='DZ_W_Contacts_Body'>
                                    <ul className='contacts'>
                                        {athleteDetails.map((chatUser, index) => (
                                            <li key={chatUser.id} className='dlab-chat-user' onClick={() => handleUserClick(chatUser.id)}>
                                                <div className='d-flex bd-highlight'>
                                                    <div className='img_cont'>
                                                        <img
                                                            src={chatUser.galleries[0]?.fileLocation} // Use different avatars as per your need
                                                            className='rounded-circle user_img'
                                                            alt=''
                                                        />
                                                        {/* <span className='online_icon'></span> */}
                                                    </div>
                                                    <div className='user_info'>
                                                        <span>{`${chatUser.firstName} ${chatUser.lastName}`}</span>
                                                        {/* <p>{`ID: ${chatUser.receiverId}`}</p> */}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <MsgBox
                                avatar1={avatar1}
                                avatar2={avatar2}
                                openMsg={openMsg}
                                senderId={senderId}
                                receiverId={receiverId}
                                offMsg={() => setOpenMsg(false)}
                            />
                        </div>
                    </Offcanvas.Body>
                </div>
            </Offcanvas>
        </>
    );
});

export default Message;
