import axios from "axios";


export default class UserManagerAPI {
  
    static fetchAthleteById = async (id) => {
        try {
			const token = localStorage.getItem("token");
			const response = await axios.get(
				`${process.env.REACT_APP_USER_PROFILE_API_URI}/getathlete/${id}`,
				{
					headers: {
						authorization: `${token}`,
					},
				}
			);

			const responseData = response.data.data;

            return responseData;
		} catch (error) {
			console.error("Error fetching speciality details:", error);
		}
    }
}