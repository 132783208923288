import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const SportOffcanvas = forwardRef((props, ref) => {
  const [AddEmploye, setAddEmploye] = useState(false);
  const [sports, setSports] = useState([]);


  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    }
  }));

  useEffect(() => {
    const fetchSports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
          headers: {
            'authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        setSports(response.data);
      } catch (error) {
        console.error('Error fetching sports data:', error);
      }
    };

    fetchSports();
  }, []);
  const validationSchema = Yup.object().shape({
    specialityTitle: Yup.string()
      .required('Specialty Name is required')
      .max(50, 'Specialty Name must be within 50 characters'),
    sportsId: Yup.array()
      .of(Yup.string().required('Sport is required'))
      .min(1, 'At least one sport must be selected')
  });


  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const token = localStorage.getItem('token');

      const sportData = {

        specialityTitle: values.specialityTitle,
        sportId: values.sportId,
        sportsId: values.sportsId
      };

      const response = await axios.post(`${process.env.REACT_APP_SPORTS_API_URI}/addspeciality`, sportData, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        }
      });

      const addedSportData = response.data.data;

      props.onSportAdded(addedSportData);

      setAddEmploye(false);
      props.onsportUpdated();
      toast.success(response?.data?.message);
    } catch (error) {
      console.error('Error adding sport:', error);
      setAddEmploye(false);
      toast.error(error?.response?.data?.message);
    }
    setSubmitting(false);
  };


  return (
    <Offcanvas show={AddEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff" placement='end'>
      <div className="offcanvas-header profile-head-h">
        <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
        <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <Formik
            initialValues={{ specialityTitle: '', sportId: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="exampleFormControlInput2" className="form-label">Speciality <span className="text-danger">*</span></label>
                    <Field type="text" className="form-control" id="exampleFormControlInput2" name="specialityTitle" />
                    <ErrorMessage name="specialityTitle" component="div" className="text-danger" />
                  </div>
                  <label htmlFor="sportsId" className="form-label">Sports <span className="text-danger">*</span></label>
                    <Select
                        name="sportsId"
                        options={sports.data.map(sport => ({ value: sport.id, label: sport.sportName }))}
                        classNamePrefix="react-select"
                        isMulti
                        id="sportsId"
                        onChange={option => {

                            setFieldValue('sportsId', option ? option.map(opt => opt.value) : []);
                            // setSelectedsportsId(option ? option.map(opt => opt.value) : []);
                        }}
                        value={values.sportsId ? values.sportsId.map(id => ({ value: id, label: sports.data.find(sport => sport.id === id)?.sportName })) : []}
                        /> 
                      <ErrorMessage name="sportId" component="div" className="text-danger" />
                  <div>
                    <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>Submit</button>
                    <Link to="#" onClick={() => setAddEmploye(false)} className="btn btn-danger light ms-1">Cancel</Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Offcanvas>
  );
});

export default SportOffcanvas;