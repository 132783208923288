/* eslint-disable jsx-a11y/img-redundant-alt */

import axios from "axios";
import parse from "html-react-parser";
import { Eye, MessageSquareText, Pencil, Trash } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";
import noimage from "../../../images/noprofile/noprofiel.png";
import AthleteOffcanvas from "../../constant/AthleteOffcanvas";
import EditAthleteOffcanvas from "../../constant/EditAthlete";
import Message from "../../constant/MessageListOffcanvas";
import ViewAthleteOffcanvas from "../../constant/ViewAthleteOffcanvas";
import MainPagetitle from "../../layouts/MainPagetitle";
import { ReactTable } from "../Common/ReactTable/ReactTable";
const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {
    if (React.isValidElement(child)) {
      const style = child.props.style;
      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(";");
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });
  return styledHtml;
};
const Athlete = ({ item }) => {
  const [loading, setLoading] = useState(true);
  const [statusPriority, setStatusPriority] = useState(null);
  const [open] = useState(true);
  const [data, setData] = useState([]);
  const [city, setCity] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [maxage, setMaxAge] = useState([]);
  const [sportName, setSportName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [selectedStateIds, setSelectedStateIds] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const [publishStatus, setPublishStatus] = useState("");
  const [subscribtionStatus, setSubscriptionstatus] = useState("");
  const [sports, setSports] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem("activePag");
    return storedPage ? parseInt(storedPage) : 0;
  });
  const [columns, setColumns] = useState([]);

  const sort = 10;
  // Fetch states data
  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      setStates(response.data.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  // Fetch cities based on selected state
  useEffect(() => {
    if (selectedStateIds.length > 0) {
      fetchCitiesByState(
        selectedStateIds.map((state) => state.value).join(",")
      );
    } else {
      setCities([]);
    }
  }, [selectedStateIds]);

  const fetchCitiesByState = async (stateIds) => {
    try {
      const token = localStorage.getItem("token");
      setCities([]);
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities`,
        {
          params: { stateId: stateIds },
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      setCities(response.data.citiesData);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleSearch = async (page) => {
    try {
      const token = localStorage.getItem("token");
      const ageParam = Array.isArray(age)
        ? age.map((a) => a.value).join(",")
        : "";
      const stateIds =
        selectedStateIds.map((state) => state.value).join(",") || "";
      const cityIds = selectedCities.map((city) => city.value).join(",") || "";
      const sportIds =
        selectedSports.map((sport) => sport.value).join(",") || "";

      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getallathlete`,
        {
          params: {
            page: page,
            limit: 20,
            age: ageParam,
            gender: Array.isArray(gender)
              ? gender.map((g) => g.value).join(",")
              : "",
            city: cityIds,
            residentialState: stateIds,
            sportsId: sportIds,
            isSubscription: subscribtionStatus?.value || "",
            isPublish: publishStatus?.value || "",
          },
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setStatusPriority(response?.data?.data);
        setData(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching athlete data:", error);
      if (error.response && error.response.status === 400) {
        toast.warn(error?.response.data);
      } else {
        toast.error(error?.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  //sport data
  const fetchSports = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_SPORTS_API_URI}/getallsports`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );

      setSports(response.data.data);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };
  useEffect(() => {
    fetchSports();
  }, []);
  // Fetch athlete data based on active page
  const fetchAthlete = async (page = 1) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getallathlete?page=${page}`,
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setStatusPriority(response?.data?.data);
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching athlete data:", error);
      if (error.response && error.response.status === 400) {
        toast.warn(error?.response.data);
      } else {
        toast.error(error?.response.data);
      }
    } finally {
      setLoading(false);
    }
  };
  const onClickPage = (page) => {
    setActivePag(page);
    sessionStorage.setItem("activePag", page);
    fetchAthlete(page + 1);
  };
  useEffect(() => {
    fetchAthlete();
    fetchStates();
  }, []);
  const pagination = useMemo(
    () =>
      Array.from(
        { length: Math.ceil(data.length / sort) },
        (_, index) => index + 1
      ),
    [data]
  );
  const handleDelete = async (id) => {
    console.log("id", id);
    const willDelete = await swal({
      title: "Are you sure you want to Archive?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        let token = localStorage.getItem("token");
        if (!token) {
          swal("Authentication error!", { icon: "error" });
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_USER_PROFILE_API_URI}/deleteathlete/${id}`,
          {},
          {
            headers: {
              authorization: token,
            },
          }
        );
        if (response?.status === 200) {
          swal("Data deleted successfully!", { icon: "success" });

          fetchAthlete();
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          swal("Data already deleted!", { icon: "warning" });
        } else {
          swal("Internal server error!", { icon: "error" });
        }
        console.error("Error deleting user:", error);
      }
    } else {
      swal("Your data is safe!", { icon: "info" });
    }
  };
  useEffect(() => {
    fetchAthlete();
    fetchAge();
  }, []);
  const handleAthleteUpdated = () => {
    fetchAthlete();
  };
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getsettings/1`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      setMaxAge(response.data.data.toAge);
      console.log("max", maxage);
      const fromAge = parseInt(response.data.data.fromAge);

      const toAge = parseInt(response.data.data.toAge);

      const options = [];
      for (let i = fromAge; i < toAge; i++) {
        options.push({ value: i, label: i.toString() }); // Store age values as objects for react-select
      }
      options.push({ value: maxage, label: maxage });
      setAgeOptions(options);
    } catch (error) {
      console.error("Error fetching age:", error);
    }
  };

  const handlePublishStatus = async (id, isPublish) => {
    console.log("id", id, "isPublish", isPublish);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/athleteispublish/${id}`,
        {
          isPublish: isPublish,
        },
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setStatusPriority(
          data.map((item) => {
            if (item.id === id) {
              return { ...item, isPublish: isPublish };
            }
            return item;
          })
        );
        setData(
          data.map((item) => {
            if (item.id === id) {
              return { ...item, isPublish: isPublish };
            }
            return item;
          })
        );
        toast.success("Status Updated!");
      }
    } catch (error) {
      console.error("Error updating publish status:", error);
      toast.error(error.response.data.message);
    }
  };

  //isApprove
  const handleStatusChange = async (id, isApprove) => {
    console.log("id", id, "isApprove", isApprove);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/athleteisapprove/${id}`,
        {
          isApprove: isApprove,
        },
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Status Updated!");
      }
    } catch (error) {
      toast.error(error?.response.data);
      console.log(error);
    }
  };
  const handleReset = () => {
    setAge("");
    setGender("");
    setCity("");
    setCities([]);
    setStates([]);
    setSelectedStateIds([]);
    setSelectedCities([]);
    setSelectedSports([]);
    setSubscriptionstatus("");
    setPublishStatus("");
    fetchStates();
    fetchSports();
    fetchAge();
  };
  useEffect(() => {
    if (
      age === "" &&
      gender === "" &&
      selectedCities === "" &&
      selectedSports === "" &&
      selectedStateIds === "" &&
      publishStatus === ""
    ) {
      handleSearch();
      fetchStates();
      fetchSports();
    }
  }, [
    age,
    gender,
    selectedStateIds,
    selectedCities,
    selectedSports,
    subscribtionStatus,
    publishStatus,
  ]);

  useEffect(() => {
    handleSearch();
  }, [
    age,
    gender,
    selectedStateIds,
    selectedCities,
    selectedSports,
    subscribtionStatus,
    publishStatus,
  ]);

  const options = [
    { value: "0", label: "All" },
    ...sports.map((sport) => ({
      value: sport.id,
      label: sport.sportName,
    })),
  ];

  const employe = useRef();
  const employee = useRef();
  const message = useRef();
  const viewathlete = useRef();
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    // Function to generate CSV data
    const generateCSVData = () => {
      const formattedData = data.map((item) => ({
        FirstName: item?.user?.firstName,
        LastName: item?.user?.lastName,
        Sport: item?.sport?.sportName,
        Age: item?.age,
        Gender: item?.gender,
        ResidentialCity: item?.cityData?.name,
        ResidentialState: item?.stateData?.name,
        Email: item?.user?.email,
        ContactNumber: item?.parentPhone,
        SubscriptionStatus: item?.isSubscription,
        ApprovalStatus: item?.isApprove,
        PublishStatus: item?.isPublish ? "Active" : "Inactive",
        School: item?.school,
        Bio: stripHtmlTags(item?.bio) || "",
        Achievements: stripHtmlTags(item?.achievements) || "",
        ParentFirstName: item?.parentFirstName,
        ParentLastName: item?.parentLastName,
        ParentEmail: item?.parentEmail,
        ParentPhone: item?.parentPhone,
        AthleteSpecialty: item?.specialities?.specialityTitle,
        CurrentAcademy: item?.currentAcademie,
        SubscriptionName: item?.subscription?.subscriptionName,
        SubscriptionAmount: item?.subscription?.subscriptionAmount,
        ProcessingFee: item?.subscription?.processingTax,
        ConvenienceFee: item?.subscription?.convenienceTax,
        ServiceFee: item?.subscription?.serviceTax,
      }));
      setCsvData(formattedData);
    };

    generateCSVData();
  }, [data]);

  useEffect(() => {
    const reqColumns = [
      {
        Header: "Profile",
        className: "",
        Cell: (localProps) => (
          <div className="">
            {localProps.row.original?.user?.galleries &&
            localProps.row.original.user.galleries.length > 0 ? (
              localProps.row.original.user.galleries?.find(
                (gallery) => gallery.fileType === "Profile Image"
              ) ? (
                <img
                  src={
                    localProps.row.original.user.galleries?.find(
                      (gallery) => gallery.fileType === "Profile Image"
                    ).fileLocation
                  }
                  className="avatar avatar-md"
                  alt="Profile Image"
                />
              ) : (
                <img
                  src={noimage}
                  className="avatar avatar-md"
                  alt="no image"
                />
              )
            ) : (
              <img src={noimage} className="avatar avatar-md" alt="no image" />
            )}
          </div>
        ),
      },
      {
        Header: "First Name",
        accessor: (row) => row.user.firstName,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-150 text-wrap">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Last Name",
        accessor: (row) => row.user.lastName,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-150 text-wrap">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Sport",
        accessor: (row) => row.sports?.map((sport, i) => sport.sportName),
        className: "",
        Cell: (localProps) => (
          <div className="">
            {localProps.row.original?.sports?.map((sport, i) => (
              <span key={i} className="text-wrap">
                {sport.sportName}
                {i < localProps.row.original?.sports?.length - 1 ? ", " : ""}
              </span>
            ))}
          </div>
        ),
      },
      {
        Header: "Age",
        accessor: "age",
        className: "w-60px",
        Cell: (localProps) => (
          <div className="">
            <p className="w-60px">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: "gender",
        className: "",
        Cell: (localProps) => (
          <div className="">
            <p className="">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Residential State",
        accessor: (row) => row.stateData?.name,
        className: "",
        Cell: (localProps) => (
          <div className="">
            <p className="">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Residential City",
        accessor: (row) => row.cityData?.name,
        className: "",
        Cell: (localProps) => (
          <div className="">
            <p className="">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Email Address",
        accessor: (row) => row.user?.email,
        className: "",
        Cell: (localProps) => (
          <div className="">
            <p className="text-wrap text-trancate">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Contact Number",
        accessor: "parentPhone",
        className: "",
        Cell: (localProps) => (
          <div className="">
            <p className="">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Subscription Status",
        accessor: "isSubscription",
        className: "",
        Cell: (localProps) => (
          <div className="">
            <p className="">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Approval Status",
        accessor: (row) => row.isApprove,
        className: "custom-select-kr",
        Cell: (localProps) => (
          <div className="">
            <select
              defaultValue={localProps.row.original?.isApprove}
              className="form-control"
              onChange={(event) =>
                handleStatusChange(
                  localProps.row.original?.user?.id,
                  event.target.value
                )
              }
            >
              <option value="Approve">Approve</option>
              <option value="Pending">Pending</option>
              <option value="Reject">Reject</option>
            </select>
          </div>
        ),
      },
      {
        Header: "Publish Status",
        // accessor: row => row.isPublish,
        className: "",
        Cell: (localProps) => (
          <div className="">
            <Form.Check
              type="switch"
              id={`custom-switch-${localProps.row.original?.user?.id}`}
              checked={localProps.row.original?.isPublish}
              onChange={(event) =>
                handlePublishStatus(
                  localProps.row.original?.id,
                  event.target.checked
                )
              }
            />
          </div>
        ),
      },
      {
        Header: "Actions",
        className: "",
        Cell: (localProps) => (
          <div className="d-flex align-items-center justify-content-center">
            {/* <div className="p-1">
              <Link
                to={"#"}
                className="btn  btn-sharp"
                data-bs-toggle="offcanvas"
                onClick={(event) => {
                  event.preventDefault();
                  viewathlete.current.showEmployeModal(
                    localProps.row.original?.user?.id
                  );
                }}
              >
                <Eye size={17} />
              </Link>
            </div> */}
            <div className="p-1">
              <Link
                to={"#"}
                className="btn btn-sharp"
                data-bs-toggle="offcanvas"
                onClick={() =>
                  employee.current.showEmployeModal(
                    localProps.row.original?.user?.id
                  )
                }
              >
                <Pencil size={17} />
              </Link>
            </div>
            <div className="p-1">
              <Link
                to={"#"}
                className="btn btn-sharp"
                onClick={(event) => {
                  event.preventDefault();
                  message.current.showEmployeModal(
                    localProps.row.original?.user?.id
                  );
                }}
              >
                <MessageSquareText size={17} />
              </Link>
            </div>
            {/* <div className="p-1">
              <Link
                to={"#"}
                className="btn  btn-sharp"
                onClick={() => handleDelete(localProps.row.original.user?.id)}
              >
                <Trash color="red" size={17} />
              </Link>
            </div> */}
          </div>
        ),
      },
    ];
    setColumns(reqColumns);
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner className="text-center" animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <MainPagetitle
        mainTitle="Athlete"
        pageTitle={"Athlete"}
        parentTitle={"Home"}
      />

      <div className="p-2">
        <div className="">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-4">
                  <div className="active-projects style-1 shorting">
                    {/* <h4 className="heading mb-0">Athlete</h4> */}
                    <div className="d-flex justify-content-end text-wrap align-items-center">
                      <div>
                        <Link
                          to={"#"}
                          className="btn btn-primary btn-sm ms-1"
                          data-bs-toggle="offcanvas"
                          onClick={() => employe.current.showEmployeModal()}
                        >
                          + Add Athlete
                        </Link>
                      </div>
                      <div className="tbl-caption d-flex justify-content-end text-wrap align-items-center">
                        <CSVLink data={csvData} filename={"athlete_data.csv"}>
                          <button className="btn btn-primary btn-sm ms-1">
                            Export
                          </button>
                        </CSVLink>
                      </div>
                    </div>

                    <Collapse in={open}>
                      <div className="cm-content-body form excerpt p-0">
                        <div className="card-body">
                          <div className="row filter-row spacing-3">
                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                id="age"
                                name="age"
                                className="mb-xl-0 mb-3"
                                classNamePrefix="select"
                                placeholder="Select Age"
                                options={ageOptions}
                                value={age}
                                onChange={setAge}
                                isMulti
                              />
                            </div>

                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                id="gender"
                                name="gender"
                                className="mb-xl-0 mb-3"
                                placeholder="Select Gender"
                                options={[
                                  { value: "Male", label: "Male" },
                                  { value: "Female", label: "Female" },
                                  { value: "Other", label: "Other" },
                                ]}
                                value={gender}
                                onChange={setGender}
                                isMulti
                              />
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                id="state"
                                name="state"
                                className="mb-xl-0 mb-3"
                                placeholder="Select State"
                                options={states.map((state) => ({
                                  value: state.id,
                                  label: state.name,
                                }))}
                                value={selectedStateIds}
                                onChange={(selectedOptions) =>
                                  setSelectedStateIds(selectedOptions)
                                }
                                isMulti
                              />
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                id="city"
                                name="city"
                                className="mb-xl-0 mb-3"
                                placeholder="Select City"
                                options={cities.map((city) => ({
                                  value: city.id,
                                  label: city.name,
                                }))}
                                value={selectedCities}
                                onChange={(selectedOptions) =>
                                  setSelectedCities(selectedOptions)
                                }
                                isMulti
                                isDisabled={!selectedStateIds.length}
                              />
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                id="sport"
                                name="sport"
                                className="mb-xl-0 mb-3"
                                placeholder="Select Sport"
                                options={options}
                                value={selectedSports}
                                onChange={setSelectedSports}
                                isMulti
                              />
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                className="mb-xl-0 mb-3"
                                placeholder="Publish Status"
                                options={[
                                  { value: "1", label: "Active" },
                                  { value: "0", label: "Inactive" },
                                ]}
                                value={publishStatus}
                                onChange={setPublishStatus}
                                isClearable
                              />
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                              <Select
                                className="mb-xl-0 mb-3"
                                placeholder="Subscription Status"
                                options={[
                                  { value: "Active", label: "Active" },
                                  { value: "Expired", label: "Expired" },
                                  { value: "Inactive", label: "Inactive" },
                                ]}
                                value={subscribtionStatus}
                                onChange={setSubscriptionstatus}
                                isClearable
                              />
                            </div>

                            {/* <div className="col-xl-1 col-sm-1 py-1 text-center">
                              <button
                                className="btn btn-primary me-2"
                                title="Click here to Search"
                                type="button"
                                onClick={() => handleSearch(1)}
                              >
                                <i className="fa fa-search me-1"></i>
                              </button>
                            </div> */}
                            <div className="col-xl-1 col-sm-1 py-1">
                              <button
                                className="btn btn-primary me-2"
                                title="Click here to Reset"
                                type="button"
                                onClick={handleReset}
                              >
                                <i className="bi bi-arrow-clockwise me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>

                    {/* <div
                      className="table-container overflow-auto"
                      style={{ maxHeight: "400px" }}
                    >
                      <table
                        id="employees-tblwrapper"
                        className="table border ItemsCheckboxSec dataTable no-footer w-100"
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Profile</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Sport</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>Residential State</th>
                            <th>Residential City</th>
                            <th>Email Address</th>
                            <th>Contact Number</th>
                            <th>Subscription Status</th>
                            <th>Approval Status</th>
                            <th className="text-center">Publish Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statusPriority?.map((item, index) => (
                            <tr key={item?.userData?.id}>
                              <td>
                                {item?.user?.galleries &&
                                item.user.galleries.length > 0 ? (
                                  item.user.galleries.find(
                                    (gallery) =>
                                      gallery.fileType === "Profile Image"
                                  ) ? (
                                    <img
                                      src={
                                        item.user.galleries.find(
                                          (gallery) =>
                                            gallery.fileType === "Profile Image"
                                        ).fileLocation
                                      }
                                      className="avatar avatar-md"
                                      alt="Profile Image"
                                    />
                                  ) : (
                                    <img
                                      src={noimage}
                                      className="avatar avatar-md"
                                      alt="no image"
                                    />
                                  )
                                ) : (
                                  <img
                                    src={noimage}
                                    className="avatar avatar-md"
                                    alt="no image"
                                  />
                                )}
                              </td>
                              <td>
                                <span>{item?.user?.firstName}</span>
                              </td>
                              <td>
                                <span>{item?.user?.lastName}</span>
                              </td>
                              <td>
                                {item?.sports?.map((sport, i) => (
                                  <span key={i} className="text-wrap">
                                    {sport.sportName}
                                    {i < item.sports.length - 1 ? ", " : ""}
                                  </span>
                                ))}
                              </td>
                              <td>
                                <span>{item?.age}</span>
                              </td>
                              <td>
                                <span>{item?.gender}</span>
                              </td>

                              <td>
                                <span>{item?.stateData?.name}</span>
                              </td>
                              <td>
                                <span>{item?.cityData?.name}</span>
                              </td>

                              <td>
                                <span className="text-primary">
                                  {item?.user?.email}
                                </span>
                              </td>
                              <td>
                                <span>{item?.parentPhone}</span>
                              </td>
                              <td>
                                <span>{item?.isSubscription}</span>
                              </td>

                              <td className="custom-select-kr">
                                <select
                                  defaultValue={item?.isApprove}
                                  className="form-control"
                                  onChange={(event) =>
                                    handleStatusChange(
                                      item?.user?.id,
                                      event.target.value
                                    )
                                  }
                                >
                                  <option value="Approve">Approve</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Reject">Reject</option>
                                </select>
                              </td>
                              <td>
                                <Form.Check
                                  type="switch"
                                  id={`custom-switch-${item?.user?.id}`}
                                  checked={item?.isPublish}
                                  onChange={(event) =>
                                    handlePublishStatus(
                                      item?.id,
                                      event.target.checked
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <div className="d-flex">
                                  <div className="p-1">
                                    <Link
                                      to={"#"}
                                      className="btn  btn-sharp"
                                      data-bs-toggle="offcanvas"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        viewathlete.current.showEmployeModal(
                                          item?.user?.id
                                        );
                                      }}
                                    >
                                      <svg
                                        width="15"
                                        height="16"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0.625 8C0.625 8 3.125 3 7.5 3C11.875 3 14.375 8 14.375 8C14.375 8 11.875 13 7.5 13C3.125 13 0.625 8 0.625 8Z"
                                          stroke="var(--primary)"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M7.5 9.875C8.53553 9.875 9.375 9.03553 9.375 8C9.375 6.96447 8.53553 6.125 7.5 6.125C6.46447 6.125 5.625 6.96447 5.625 8C5.625 9.03553 6.46447 9.875 7.5 9.875Z"
                                          stroke="var(--primary)"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </Link>{" "}
                                  </div>
                                  <div className="p-1">
                                    <Link
                                      to={"#"}
                                      className="btn  light btn-sharp"
                                      data-bs-toggle="offcanvas"
                                      onClick={() =>
                                        employee.current.showEmployeModal(
                                          item?.user?.id
                                        )
                                      }
                                    >
                                      <svg
                                        width="15"
                                        height="16"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.625 2.37519C10.7892 2.21104 10.984 2.08082 11.1985 1.99199C11.413 1.90315 11.6429 1.85742 11.875 1.85742C12.1071 1.85742 12.337 1.90315 12.5515 1.99199C12.766 2.08082 12.9608 2.21104 13.125 2.37519C13.2892 2.53934 13.4194 2.73422 13.5082 2.94869C13.597 3.16317 13.6428 3.39304 13.6428 3.62519C13.6428 3.85734 13.597 4.08721 13.5082 4.30168C13.4194 4.51616 13.2892 4.71104 13.125 4.87519L4.6875 13.3127L1.25 14.2502L2.1875 10.8127L10.625 2.37519Z"
                                          stroke="var(--primary)"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </Link>{" "}
                                  </div>
                                  <div className="p-1">
                                    <Link
                                      to={"#"}
                                      className="btn light btn-sharp"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        message.current.showEmployeModal(
                                          item?.user?.id
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-chat-text"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105" />
                                        <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8m0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                      </svg>
                                    </Link>{" "}
                                  </div>
                                  <div className="p-1">
                                    <Link to={"#"} className="btn  btn-sharp">
                                      <button
                                        onClick={() =>
                                          handleDelete(item.user?.id)
                                        }
                                        className="btn btn-sharp d-flex align-items-end"
                                      >
                                        <i class="bi bi-archive text-danger"></i>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {activePag + 1} page of {data.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example2_paginate"
                      >
                        <Link
                          className={`paginate_button previous mx-2 ${
                            activePag === 0 ? "disabled" : ""
                          }`}
                          to=""
                          onClick={(e) => {
                            if (activePag > 0) {
                              onClickPage(activePag - 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          
                          Previous
                        </Link>
                        <span>
                          {pagination.map((number, i) => {
                            const isFirst = i === 0;
                            const isLast = i === pagination.length - 1;
                            const isNearActive = Math.abs(activePag - i) <= 2;

                            // Render ellipsis for hidden middle items
                            if (!isFirst && !isLast && !isNearActive) {
                              if (pagination[i - 1] !== "...") {
                                return (
                                  <span
                                    key={`ellipsis-${i}`}
                                    className="ellipsis"
                                  >
                                    ...
                                  </span>
                                );
                              }
                              return null;
                            }

                            return (
                              <Link
                                key={i}
                                to=""
                                className={`paginate_button mx-2 ${
                                  activePag === i ? "current" : ""
                                }`}
                                
                                onClick={(e) => {
                                  e.preventDefault();
                                  onClickPage(i);
                                }}
                              >
                                {number}
                              </Link>
                            );
                          })}
                        </span>
                        <Link
                          className={`paginate_button next mx-2 ${
                            activePag + 1 >= pagination.length ? "disabled" : ""
                          }`}
                          to=""
                          onClick={(e) => {
                            if (activePag + 1 < pagination.length) {
                              onClickPage(activePag + 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          
                          Next
                        </Link>
                      </div>
                    </div> */}

                    <ReactTable
                      columns={columns}
                      data={statusPriority || []}
                      title={"Athlete"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Message ref={message} Title="Message" />
      <AthleteOffcanvas
        ref={employe}
        Title="Add Athlete"
        onAthleteUpdated={handleAthleteUpdated}
      />
      <EditAthleteOffcanvas
        ref={employee}
        Title="Update Athlete"
        onAthleteUpdated={handleAthleteUpdated}
      />
      <ViewAthleteOffcanvas ref={viewathlete} Title="View Athlete Details" />
    </>
  );
};

export default Athlete;
