import JoditEditor from "jodit-react";
import { placeholder } from "jodit/esm/plugins/placeholder/placeholder";
import React, { useRef } from "react";

const config = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "paragraph",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "link",
    "image",
    "table",
    "video",
    "|",
    "brush",
    "|",
    "undo",
    "redo",
  ],

  enter: "p",
  removeButtons: ["break"],
  disablePlugins:
    "powered-by-jodit,xpath,add-new-line,about,ai-assistant,preview,stat",
  placeholder: 'Start writing...'
};

const TextEditor = ({ title, content, setContent, required }) => {
  const editor = useRef(null);

  const customConfig = {
    ...config,
    askBeforePasteHTML: false,
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card b-0">
            <div className="card-header p-1">
              <h4 className="card-title mx-3">
                {title} {required && <span className="text-danger">*</span>}
              </h4>{" "}
            </div>
            <div className="card-body custom-ekeditor">
              <JoditEditor
                ref={editor}
                value={content}
                config={customConfig}
                onBlur={(newContent) => setContent(newContent)}
                onPaste={(e) => {
                  e.preventDefault();
                  const clipboardData = e.clipboardData || window.clipboardData;
                  const html = clipboardData?.getData("text/html") || "";
                  const text = clipboardData?.getData("text/plain") || "";
                  if (html) {
                    editor.current?.editor.s.insertHTML(html);
                  } else {
                    editor.current?.editor.s.insertHTML(text);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TextEditor;
