import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import swal from "sweetalert";
import PageTitle from "../../../layouts/PageTitle";
import TextEditor from "../../Common/TextEditor";

const Addannounce = () => {
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const fetchSports = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_SPORTS_API_URI}/getallsports`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );

      const sportsOptions = response.data.data.map((sport) => ({
        value: sport.id,
        label: sport.sportName,
      }));
      sportsOptions.unshift({ value: "general", label: "General" });

      setSports(sportsOptions);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  useEffect(() => {
    fetchSports();
  }, []);

  const saveAnnouncement = async () => {
    try {
      const token = localStorage.getItem("token");
      const requestData = {
        announcementTitle: title,
        announcementDescription: shortDescription,
        rolesId: selectedRole.value,
        sportsId:
          selectedRole.value === "general" ? "general" : selectedSport.value,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFICATION_API_URI}/addannouncement`,
        requestData,
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        swal("Success", "Announcement saved successfully", "success");
        // Clear form data
        setTitle("");
        setShortDescription("");
        setSelectedSport(null);
        setSelectedRole(null);
      } else {
        swal("Error", "Failed to save announcement", "error");
      }
    } catch (error) {
      swal("Error", "An error occurred while saving the announcement", "error");
    }
  };

  const roleOptions = [
    { value: "general", label: "General" },
    { value: 2, label: "Athlete" },
    { value: 3, label: "Coach" },
    { value: 4, label: "Academy" },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="h-auto">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-lg-11">
                    <PageTitle activeMenu="Add" motherMenu="Announcement" />
                  </div>
                  <div className="col-lg-1">
                    <div className="content-title border-bot">
                      <button
                        type="button"
                        className="btn btn-secondary light my-2"
                        onClick={saveAnnouncement}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-around">
                  <div className="col-lg-6">
                    <label>Profile</label>
                    <Select
                      id="roleSelect"
                      className=""
                      value={selectedRole}
                      onChange={(selectedOption) => {
                        setSelectedRole(selectedOption);
                        if (selectedOption.value === "general") {
                          setSelectedSport({
                            value: "general",
                            label: "General",
                          });
                        } else {
                          setSelectedSport(null);
                        }
                      }}
                      options={roleOptions}
                      placeholder="Select a profile"
                    />
                  </div>

                  {selectedRole && selectedRole.value !== "general" && (
                    <div className="col-lg-5">
                      <label>Sport</label>
                      <Select
                        id="sportSelect"
                        className=""
                        value={selectedSport}
                        onChange={setSelectedSport}
                        options={sports}
                        placeholder="Select a sport"
                      />
                    </div>
                  )}
                </div>

                <TextEditor
                  content={title}
                  setContent={setTitle}
                  title={"Title"}
                />
                <TextEditor
                  content={shortDescription}
                  setContent={setShortDescription}
                  title={"Description"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addannounce;
