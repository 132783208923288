import React from "react";
import AboutConnect from "./About/AboutConnect";
import Banner from "./About/Banner";
import Testimonial from "./About/Testimonial";
const AboutPage = () => {
  return (
    <>
      <Banner />
      <AboutConnect />
      <Testimonial />
    </>
  );
};

export default AboutPage;
