import React, { useContext } from "react";

/// React router dom
import { Outlet, Route, Routes } from "react-router-dom";

/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Athlete from "./components/Dashboard/Athlete";
import AthleteReport from "./components/Dashboard/AthleteReport";
import BillingState from "./components/Dashboard/BillingState";
import HomeBlog from "./components/Dashboard/Blog";
import Club from "./components/Dashboard/Club-profile";
import ClubReport from "./components/Dashboard/ClubReport";
import Coach from "./components/Dashboard/Coach-profile";
import ContactList from "./components/Dashboard/ContactList";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Finance from "./components/Dashboard/Finance";
import Home from "./components/Dashboard/Home";
import Messenger from "./components/Dashboard/Messenger";
import Performance from "./components/Dashboard/Performance";
import Profile from "./components/Dashboard/Profile";
import Projects from "./components/Dashboard/Projects";
import PromoCode from "./components/Dashboard/PromoCode";
import PromoReport from "./components/Dashboard/PromoReport";
import Report from "./components/Dashboard/Report";
import Speciality from "./components/Dashboard/specialty";
import Sport from "./components/Dashboard/sport";
import SubscriptionSetup from "./components/Dashboard/SubscriptionSetup";
import TaskSummary from "./components/Dashboard/TaskSummary";
import Header from "./layouts/nav/Header";
//Update Pages
import SvgIcons from "./components/Dashboard/SvgIcons";

//Apps
import AddRole from "./components/AppsMenu/AppProfile/AddRole";
import AppCustomer from "./components/AppsMenu/AppProfile/AppCustomer";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import AppProfile2 from "./components/AppsMenu/AppProfile/AppProfile2";
import CustomerProfile from "./components/AppsMenu/AppProfile/CustomerProfile";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import User from "./components/AppsMenu/AppProfile/User";
import UserRoles from "./components/AppsMenu/AppProfile/UserRoles";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import Contacts from "./components/AppsMenu/Contacts";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";

//CMS
import AboutPage from "./components/Cms/AboutPage";
import AddBlog from "./components/Cms/AddBlog";
import AddMail from "./components/Cms/AddMail";
import AthleteIntake from "./components/Cms/AthleteIntakeCms";
import CmsBlog from "./components/Cms/Blog";
import BlogCategory from "./components/Cms/BlogCategory";
import Contact from "./components/Cms/ContactCms";
import Content from "./components/Cms/Content";
import ContentAdd from "./components/Cms/ContentAdd";
import EmailTemplate from "./components/Cms/EmailTemplate";
import HomePage from "./components/Cms/HomePage";
import Menu from "./components/Cms/Menu";
import PrivacyPolicy from "./components/Cms/PrivacyPolicy";
import TermsConditions from "./components/Cms/TermsConditions";

//Aikit pages

import AIMenu from "./components/Aikit/AIMenu";
import AutoWriter from "./components/Aikit/AutoWriter";
import Chatbot from "./components/Aikit/Chatbot";
import FineTuneModels from "./components/Aikit/FineTuneModels";
import ImportExport from "./components/Aikit/ImportExport";
import Repurpose from "./components/Aikit/Repurpose";
import RSS from "./components/Aikit/Rss";
import Scheduler from "./components/Aikit/Scheduler";
import Settings from "./components/Aikit/Settings";

/// Product List
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";

/// Charts
import ApexChart from "./components/charts/apexcharts";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import SparklineChart from "./components/charts/Sparkline";

/// Bootstrap
import UiAccordion from "./components/bootstrap/Accordion";
import UiAlert from "./components/bootstrap/Alert";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiGrid from "./components/bootstrap/Grid";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiModal from "./components/bootstrap/Modal";
import UiPagination from "./components/bootstrap/Pagination";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
// Widget
import Widget from "./pages/Widget";
/// Table
import BootstrapTable from "./components/table/BootstrapTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import SortingTable from "./components/table/SortingTable/SortingTable";
/// Form
import Element from "./components/Forms/Element/Element";
import FormValidation from "./components/Forms/FormValidation/FormValidation";
import Pickers from "./components/Forms/Pickers/Pickers";
import Wizard from "./components/Forms/Wizard/Wizard";
/// Pages
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import LockScreen from "./pages/LockScreen";

import axios from "axios";
import { ThemeContext } from "../context/ThemeContext";
import Addannounceathlete from "./components/Cms/AddAnnoucement/athlete";
import Addannounceclub from "./components/Cms/AddAnnoucement/club";
import Addannouncecoach from "./components/Cms/AddAnnoucement/Coach";
import AddAnnouncement from "./components/Cms/Addannouncement";
import Announcement from "./components/Cms/Announcement";
import MobileBanner from "./components/Cms/MobileBanner";
import TextEditor from "./components/Common/TextEditor";
import AdminProfile from "./components/Dashboard/AdminProfile";
import ChangePassword from "./components/Dashboard/ChangePassword";

const Markup = () => {
  const fetchAdminData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          authorization: ` ${token}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getadmin`,
        config
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const allroutes = [
    // Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "dashboard-dark", component: <DashboardDark /> },
    { url: "promo-code", component: <PromoCode /> },
    { url: "performance", component: <Performance /> },
    { url: "project", component: <Projects /> },
    { url: "task-summary", component: <TaskSummary /> },
    { url: "blog-1", component: <HomeBlog /> },
    { url: "subscription-setup", component: <SubscriptionSetup /> },
    { url: "reports", component: <Report /> },
    { url: "finance", component: <Finance /> },
    { url: "athlete", component: <Athlete /> },
    { url: "sport", component: <Sport /> },
    { url: "speciality", component: <Speciality /> },
    { url: "messenger", component: <Messenger /> },
    { url: "coach-profile", component: <Coach /> },
    { url: "club-profile", component: <Club /> },
    { url: "profile", component: <Profile /> },
    { url: "billing-state", component: <BillingState /> },
    { url: "athlete-report", component: <AthleteReport /> },
    { url: "club-report", component: <ClubReport /> },
    { url: "promo-report", component: <PromoReport /> },
    { url: "contact", component: <ContactList /> },
    { url: "announcement", component: <Announcement /> },
    { url: "add-announcement", component: <AddAnnouncement /> },
    { url: "add-athleteannouncement", component: <Addannounceathlete /> },
    { url: "add-coachannouncement", component: <Addannouncecoach /> },
    { url: "add-clubannouncement", component: <Addannounceclub /> },
    { url: "app-banner", component: <MobileBanner /> },

    //Update Pages
    { url: "svg-icon", component: <SvgIcons /> },

    ///Cms
    { url: "content", component: <Content /> },
    { url: "menu", component: <Menu /> },
    { url: "email-template", component: <EmailTemplate /> },
    { url: "blog", component: <CmsBlog /> },
    { url: "athlete-intake", component: <AthleteIntake /> },
    { url: "add-homepage", component: <HomePage /> },
    { url: "terms-conditions", component: <TermsConditions /> },
    { url: "privacy-policy", component: <PrivacyPolicy /> },
    { url: "about-page", component: <AboutPage /> },
    { url: "contact-us", component: <Contact /> },
    { url: "add-content", component: <ContentAdd /> },
    { url: "add-email", component: <AddMail /> },
    { url: "add-blog", component: <AddBlog /> },
    { url: "blog-category", component: <BlogCategory /> },

    ///AiKit
    { url: "auto-write", component: <AutoWriter /> },
    { url: "scheduled", component: <Scheduler /> },
    { url: "repurpose", component: <Repurpose /> },
    { url: "rss", component: <RSS /> },
    { url: "chatbot", component: <Chatbot /> },
    { url: "fine-tune-models", component: <FineTuneModels /> },
    { url: "prompt", component: <AIMenu /> },
    { url: "setting", component: <Settings /> },
    { url: "import", component: <ImportExport /> },

    //Apps
    { url: "contacts", component: <Contacts /> },
    { url: "user", component: <User /> },
    { url: "user-roles", component: <UserRoles /> },
    { url: "add-role", component: <AddRole /> },
    { url: "app-profile", component: <AppProfile /> },
    { url: "app-profile-2", component: <AppProfile2 /> },
    { url: "edit-profile", component: <EditProfile /> },
    { url: "post-details", component: <PostDetails /> },
    { url: "customer", component: <AppCustomer /> },
    { url: "customer-profile", component: <CustomerProfile /> },

    // Apps
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },

    // Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-customers", component: <Customers /> },
    // Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },
    // Bootstrap
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },
    // Plugin
    { url: "uc-select2", component: <Select2 /> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },
    // Widget
    { url: "widget-basic", component: <Widget /> },
    // Form
    { url: "form-element", component: <Element /> },
    { url: "form-wizard", component: <Wizard /> },
    { url: "form-textEditor", component: <TextEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },

    // table
    { url: "table-filtering", component: <FilteringTable /> },
    { url: "table-sorting", component: <SortingTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },
    { url: "change-password", component: <ChangePassword /> },
    {
      url: "admin-profile",
      component: <AdminProfile updateAdminData={fetchAdminData} />,
    },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
        <Header updateAdminData={fetchAdminData} />
        <Routes>
          {allroutes.map((route, index) => (
            <Route key={index} path={route.url} element={route.component} />
          ))}
        </Routes>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
