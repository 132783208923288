// components/MediaPopup.js
import React from "react";
import { Modal } from "react-bootstrap";

const MediaPopup = ({ show, handleClose, type, src }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "image"
            ? "Image Preview"
            : type === "video"
            ? "Video Preview"
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === "image" ? (
          <img src={src} alt="Preview" className="img-fluid w-100" />
        ) : type === "video" ? (
          <video controls className="w-100">
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default MediaPopup;
