import axios from "axios";
import { Field, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Select from "react-select";
import TextEditor from "../components/Common/TextEditor";
import SportsManagerService from "../../api/sportsManager/sportsManager.service";

const validate = (values) => {
  const errors = {};
  //Profile
  //  if (!values.profileImg) {
  //   errors.profileImg = 'Profile picture is required';
  // }
  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Too Long";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length > 15) {
    errors.lastName = "Too Long";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email format";
  }

  if (!values.phone) {
    errors.phone = "Required";
  } else if (!/^\d{10}$/.test(values.phone)) {
    errors.phone = "Invalid phone number! Must be 10 digits long.";
  }

  if (!values.bio) {
    errors.bio = "Required";
  } else {
    const wordCount = values.bio.trim().split(/\s+/).length; // Split by whitespace and count
    if (wordCount > 1000) {
      console.log(wordCount);
      errors.bio = " Cannot exceed 1000 words";
    }
  }

  // Achievements Validation
  if (!values.achievements) {
    errors.achievements = "Required";
  } else {
    const wordCount = values.achievements.trim().split(/\s+/).length;
    console.log(wordCount);

    if (wordCount > 1000) {
      errors.achievements = " Cannot exceed 1000 words";
    }
  }

  if (!values.ageYouCoach) {
    errors.ageYouCoach = "Required";
  }
  if (!values.sportsId) {
    errors.sportsId = "Required";
  }

  // if (!values.coachSpecialty) {
  //   errors.coachSpecialty = 'Required';
  // }

  if (!values.genderYouCoach || values.genderYouCoach.length === 0) {
    errors.genderYouCoach = "At least one gender you coach is required";
  }
  // console.log(errors)
  return errors;
};
const initialValues = {
  profileImg: "",
  firstName: "",
  lastName: "",
  email: "",
  ageYouCoach: "",
  phone: "",
  city: "",
  state: "",
  bio: "",
  age: "",
  gender: "",
  achievements: "",
  twitterLink: "",
  instagramLink: "",
  websiteLink: "",
  sportsId: "",
  coachSpecialty: "",
  genderYouCoach: "",
  isApprove: "",
  isPublish: "",
};
const CoachOffcanvas = forwardRef((props, ref) => {
  const [addEmploye, setAddEmploye] = useState(false);
  const [selectedsportsId, setSelectedsportsId] = useState("");
  const [coachData, setcoachData] = useState([]);
  const [sports, setSports] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [profileFile, setprofileFile] = useState(null);
  const [CoachSpecialities, setCoachSpecialities] = useState([]);
  const [showSelectInput, setShowSelectInput] = useState(true);
  const [cities, setCities] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [state, setState] = useState([]);
  const [maxage, setMaxAge] = useState([]);
  const [selectedCityIds, setSelectedCityIds] = useState([]);

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    },
  }));

  useEffect(() => {
    fetchCoach();
    fetchSports();
    fetchStates();
    fetchAge();
    if (selectedsportsId) {
      console.log(selectedsportsId);
      fetchCoachSpecialities(selectedsportsId);
    }
  }, [selectedsportsId]);
  const handleStateChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedStateId(selectedIds);
    console.log("Selected State IDs:", selectedIds);
  };

  // Modify the handleCityChange function to handle multi-select for cities
  const handleCityChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedCityIds(selectedIds);
    console.log("Selected City IDs:", selectedIds);
  };


useEffect(() => {
    if(selectedsportsId){
      //Optimize to get specific sport IDs only upon addition of new sport in the multi select
      fetchCoachSpecialities(selectedsportsId);
    }

  }, [selectedsportsId]);


  const fetchCoachSpecialities = async (sportsId) => {
    try {
      const response = await SportsManagerService.getSpecialitiesBySport(sportsId);

      console.log("Response from fetchCoachSpecialities:", response);

      if (response.status === 404) {
        setCoachSpecialities([]); // No data available, set specialties to an empty array
        console.log("No athlete specialties found for the selected sport.");
        setShowSelectInput(true); // Set showSelectInput to true to render the select input
      } else if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        const specialties = response.data.data.map((spec) => ({
          id: spec[0].id,
          specialityTitle: spec[0].specialityTitle,
        }));
        setCoachSpecialities(specialties);
        console.log("Athlete specialties:", specialties);
        setShowSelectInput(false); // Data available, set showSelectInput to false to render a text field
      } else {
        console.error(
          "Error: Unable to find athlete specialties in the response data"
        );
      }
    } catch (error) {
      console.error("Error fetching athlete specialties:", error);
    }
  };
  const fetchStates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      setState(response.data.data);
      setCities([]);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  useEffect(() => {
    setCities([]);
    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    }
  }, [selectedStateId]);
  console.log(selectedStateId);
  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities?stateId=${id}`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      console.log("city", response.data.citiesData);
      if (response.data.citiesData && response.data.citiesData.length > 0) {
        // Flatten the citiesData array directly
        const citiesData = response.data.citiesData.flat().map((city) => ({
          value: city.id,
          label: city.name,
        }));

        setCities(citiesData);
      } else {
        console.error(
          "Error fetching cities: citiesData is undefined or empty"
        );
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  // Create athlete
  const handleSubmit = (values) => {
    const stateString = selectedStateId.join(",");
    const cityString = selectedCityIds.join(",");
    console.log("city", cityString);
    console.log("Submitting form with values:", values);

    const submit = async () => {
      values.ageYouCoach = String(values.ageYouCoach);
      values.genderYouCoach = String(values.genderYouCoach);
      const token = localStorage.getItem("token");

      try {
        const formData = new FormData();

        formData.append("profileImg", values.logoId);
        formData.append("firstName", values.firstName);
        formData.append("lastName", values.lastName);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append(
          "ageYouCoach",
          Array.isArray(values.ageYouCoach)
            ? values.ageYouCoach.map((opt) => opt.value).join(",")
            : values.ageYouCoach
        );
        formData.append("phone", values.phone);
        formData.append("state", stateString);
        formData.append("city", cityString);
        formData.append("bio", values.bio);
        formData.append("age", values.age);
        formData.append("gender", values.gender);
        formData.append("achievements", values.achievements);
        formData.append("lookingFor", values.achievements);
        formData.append("twitterLink", values.twitterLink);
        formData.append("instagramLink", values.instagramLink);
        formData.append("websiteLink", values.websiteLink);
        formData.append("sportsId", values.sportsId);
        formData.append(
          "coachSpecialty",
          Array.isArray(values.coachSpecialty)
            ? values.coachSpecialty.map((opt) => opt.value).join(",")
            : values.coachSpecialty
        );
        formData.append(
          "genderYouCoach",
          Array.isArray(values.genderYouCoach)
            ? values.genderYouCoach.map((opt) => opt.value).join(",")
            : values.genderYouCoach
        );
        formData.append("isApprove", values.isApprove);
        formData.append("isPublish", values.isPublish);

        const response = await axios.post(
          `${process.env.REACT_APP_USER_PROFILE_API_URI}/admin-addcoach`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: token ? `${token}` : "",
            },
          }
        );
        if (response.status === 201) {
          const userId = response.data.data.id;
          console.log(userId);

          if (previewImage) {
            await handleProfileUpload(userId);
          }
          setAddEmploye(false);
          console.log("Response:", response.data);
          props.onCoachUpdated();
          toast.success(response.data.message);
          fetchCoach();
        } else {
          console.error("Error:", response.status);
        }
      } catch (error) {
        console.log("===============error===========",error);
        toast.error("Error: " + error?.response?.data?.message);

        console.error("Error:", error);
      }
    };
    submit();
  };
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");
      console.log(id);
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getsettings/1`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      setMaxAge(response.data.data.toAge);
      const fromAge = parseInt(response.data.data.fromAge); // Ensure fromAge is parsed as an integer
      const toAge = parseInt(response.data.data.toAge); // Ensure toAge is parsed as an integer

      const options = [];
      for (let i = fromAge; i < toAge; i++) {
        options.push({ value: i.toString(), label: i.toString() }); // Create object with value and label properties
      }

      // Push maxAge as the last option
      options.push({
        value: response.data.data.toAge.toString(),
        label: response.data.data.toAge.toString(),
      });

      setAgeOptions(options);
    } catch (error) {
      console.error("Error fetching age:", error);
    }
  };

  const fetchCoach = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getallcoach`,
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setcoachData(response.data.data);
    } catch (error) {
      console.error("Error fetching promo codes:", error);
    }
  };
  //profile
  const handleProfileUpload = async (userId) => {
    try {
      const formData = new FormData();
      formData.append("fileLocation", profileFile);
      formData.append("description", "Profile Image");
      formData.append("isActive", "true");
      formData.append("fileType", "Profile Image");
      formData.append("isApproved", "true");
      formData.append("userId", userId);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/upload-singlefile`,
        formData,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );

      return response.data.data.id;
    } catch (error) {
      console.error("Error uploading  Profile:", error);
      throw new Error("Error uploading  Profile");
    }
  };
  const fetchSports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SPORTS_API_URI}/getallsports`
      );
      setSports(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  // Sport data fetch
  const handleProfileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxFileSize = 200 * 1024; // 200 KB in bytes

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        console.log(
          "Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file."
        );
        toast.error(
          "Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file."
        );
        e.target.value = null;
        setPreviewImage(null);
        return;
      }

      // Check if the file size exceeds the limit
      if (file.size >= maxFileSize) {
        console.log("File size exceeds the limit of 200 KB.");
        toast.error("File size exceeds the limit of 200 KB.");
        e.target.value = null; // Reset the file input field
        setPreviewImage(null); // Clear the preview image
        return;
      }

      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
          // If dimensions are within the allowed range
          console.log("Selected file:", file);
          setprofileFile(file);

          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          // If dimensions are not within the allowed range
          console.log(
            "Image dimensions should be between 290 and 300 pixels in both height and width."
          );
          toast.error(
            "Image dimensions should be between 290 and 300 pixels in both height and width."
          );
          e.target.value = null;
          setPreviewImage(null);
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      console.log("No file selected.");
      setprofileFile(null);
      setPreviewImage(null);
    }
  };

  const [previewImage, setPreviewImage] = useState(null);
  return (
    <>
      <Offcanvas
        show={addEmploye}
        onHide={() => setAddEmploye(false)}
        className="w-100"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h3 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddEmploye(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={(values) => {
                console.log("Form values:", values);
                handleSubmit(values);
              }}
              enableReinitialize={true}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                setFieldTouched,
                values,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-xl-6 mb-3">
                    <label>Profile Picture</label>
                    {previewImage && (
                      <div>
                        <img
                          src={previewImage}
                          className="mb-3"
                          alt="Preview"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    )}
                    <div className="dz-default dlab-message upload-img mb-3">
                      <input
                        type="file"
                        id="profileImg"
                        name="profileImg"
                        onChange={handleProfileChange}
                        className="form-control"
                      />

                      <p className="text-danger">{errors.profileImg}</p>
                      <p className="text-warning fs-12">
                        Height & Width should be between 290 - 300 pixels <br />
                        only GIF, JPG, JPEG, PNG, or SVG images up to 200 KB.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                      />
                      {touched.firstName && errors.firstName && (
                        <p className="text-danger">{errors.firstName}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                      />
                      {touched.lastName && errors.lastName && (
                        <p className="text-danger">{errors.lastName}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="exampleFormControlInput5"
                        className="form-label"
                      >
                        Email<span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                      />
                      {touched.email && errors.email && (
                        <p className="text-danger">{errors.email}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">
                      Contact Number
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="tel"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder="Phone Number"
                      />

                      {touched.phone && errors.phone && (
                        <p className="text-danger">{errors.phone}</p>
                      )}
                    </div>

                    <div className="col-xl-12 mb-3">
                      {/* <label className="form-label col-lg-11">
                        Bio <span className="text-danger">*</span>
                      </label> */}
                      <Field name="bio">
                        {({ field, form }) => (
                          // <CKEditor
                          //   editor={ClassicEditor}
                          //   data={field.value || ''}
                          //   onChange={(event, editor) => {
                          //     const data = editor.getData();
                          //     form.setFieldValue('bio', data);
                          //   }}
                          //   onBlur={() => form.setFieldTouched('bio', true)}
                          //   onError={(error) => {
                          //     console.error('CKEditor Error:', error);
                          //   }}
                          // />
                          <TextEditor
                            content={field.value}
                            setContent={(newContent) => {
                              form.setFieldValue(field.name, newContent);
                              form.setFieldTouched("bio", true);
                            }}
                            title={"Tell us about your coaching experience, background, and what makes you unique."}
                            required={true}
                          />
                        )}
                      </Field>
                      {touched.bio && errors.bio && (
                        <p className="text-danger">{errors.bio}</p>
                      )}
                    </div>



                    <h3 className="profile-head">Sport Details</h3>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="state" className="form-label">
                      State(s) You Serve
                      </label>

                      <Select
                        name="state"
                        options={state.map((state) => ({
                          value: state.id,
                          label: state.name,
                        }))}
                        className=""
                        onChange={handleStateChange}
                        isMulti
                      />

                      {touched.state && errors.state && (
                        <p className="text-danger">{errors.state}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="city" className="form-label">
                      City(ies) You Serve
                      </label>

                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        classNamePrefix="select"
                        isMulti
                        onChange={handleCityChange}
                      />

                      {touched.city && errors.city && (
                        <p className="text-danger">{errors.city}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="sportsId" className="form-label">
                        Primary Sport(s) <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="sportsId"
                        options={sports.map((sport) => ({
                          value: sport.id,
                          label: sport.sportName,
                        }))}
                        classNamePrefix="react-select"
                        id="sportsId"
                        onChange={(option) => {
                          setFieldValue("sportsId", option.value);
                          setSelectedsportsId(option.value);
                        }}
                        value={
                          values.sportsId
                            ? {
                                value: values.sportsId,
                                label: sports.find(
                                  (sport) => sport.id === values.sportsId
                                )?.sportName,
                              }
                            : null
                        }
                      />
                      {touched.sportsId && errors.sportsId && (
                        <p className="text-danger">{errors.sportsId}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="coachSpecialty" className="form-label">
                        Speciality(s)
                      </label>
                      <Select
                        name="coachSpecialty"
                        disabled={showSelectInput}
                        options={CoachSpecialities.map((specialty) => ({
                          value: specialty.id,
                          label: specialty.specialityTitle,
                        }))}
                        classNamePrefix="react-select"
                        isMulti
                        id="coachSpecialty"
                        onChange={(selectedOption) => {
                          const selectedValues = selectedOption
                            ? selectedOption.map((option) => option.value)
                            : [];
                          setFieldValue(
                            "coachSpecialty",
                            selectedValues.join(",")
                          );
                        }}
                      />
                      {touched.coachSpecialty && errors.coachSpecialty && (
                        <p className="text-danger">{errors.coachSpecialty}</p>
                      )}
                    </div>                
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="ageYouCoach" className="form-label">
                      Age(s) You Coach <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="ageYouCoach"
                        options={ageOptions}
                        isMulti
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          const selectedValues = selectedOption
                            ? selectedOption.map((option) => option.value)
                            : [];
                          setFieldValue(
                            "ageYouCoach",
                            selectedValues.join(",")
                          );
                        }}
                      />
                      {touched.ageYouCoach && errors.ageYouCoach && (
                        <p className="text-danger">{errors.ageYouCoach}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="genderYouCoach" className="form-label">
                      Gender(s) You Coach <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="genderYouCoach"
                        options={[
                          { value: "Male", label: "Male" },
                          { value: "Female", label: "Female" },
                          { value: "Other", label: "Other" },
                        ]}
                        onChange={(selectedOption) => {
                          const selectedValues = selectedOption
                            ? selectedOption.map((option) => option.value)
                            : [];
                          setFieldValue(
                            "genderYouCoach",
                            selectedValues.join(",")
                          );
                        }}
                        isMulti
                        classNamePrefix="select"
                      />
                      {touched.genderYouCoach && errors.genderYouCoach && (
                        <p className="text-danger">{errors.genderYouCoach}</p>
                      )}
                    </div>             
                    <div className="col-xl-12 mb-3">
                      {/* <label className="form-label col-lg-11">
                      Share your coaching achievements and what excites you about connecting with athletes here{" "}
                        <span className="text-danger">*</span>
                      </label>  */}
                      <Field name="achievements">
                        {({ field, form }) => (
                          // <CKEditor
                          //   editor={ClassicEditor}
                          //   data={field.value || ""}
                          //   onChange={(event, editor) => {
                          //     const data = editor.getData();
                          //     form.setFieldValue("achievements", data);
                          //   }}
                          //   onBlur={() =>
                          //     form.setFieldTouched("achievements", true)
                          //   }
                          //   onError={(error) => {
                          //     console.error("CKEditor Error:", error);
                          //   }}
                          // />
                          <TextEditor
                            content={field.value}
                            setContent={(newContent) => {
                              form.setFieldValue(field.name, newContent);
                              form.setFieldTouched("achievements", true);
                            }}
                            title={"Share your coaching achievements and what excites you about connecting with athletes here "}
                            required={true}
                          />
                        )}
                      </Field>
                      {touched.achievements && errors.achievements && (
                        <p className="text-danger">{errors.achievements}</p>
                      )}
                    </div>

                    <h3 className="profile-head">Social Media</h3>

                    
                 

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="twitterLink" className="form-label">
                        X Profile Link
                      </label>
                      <Field
                        type="text"
                        name="twitterLink"
                        className="form-control"
                        id="twitterLink"
                        placeholder="X Profile Link"
                      />
                      {touched.twitterLink && errors.twitterLink && (
                        <p className="text-danger">{errors.twitterLink}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="instagramLink" className="form-label">
                        Instagram Profile Link
                      </label>
                      <Field
                        type="text"
                        name="instagramLink"
                        className="form-control"
                        id="instagramLink"
                        placeholder="Instagram Profile Link"
                      />
                      {touched.instagramLink && errors.instagramLink && (
                        <p className="text-danger">{errors.instagramLink}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="exampleFormControlInput4"
                        className="form-label"
                      >
                        Website Link
                      </label>
                      <Field
                        type="text"
                        name="websiteLink"
                        className="form-control"
                        id="websiteLink"
                        placeholder="Website Link"
                      />
                      {touched.websiteLink && errors.websiteLink && (
                        <p className="text-danger">{errors.websiteLink}</p>
                      )}
                    </div> 


                     {/* <h3 className='profile-head'>Profile Status</h3>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="isApprove" className="form-label">
                        Approval Status <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        name="isApprove"
                        className="form-control"
                        id="isApprove"
                      >
                        <option value="" disabled>Select Status</option>
                        <option value="Approve">Approve</option>
                        <option value="Pending">Pending</option>
                        <option value="Reject">Reject</option>
                      </Field>

                      {touched.isApprove && errors.isApprove && <p className='text-danger'>{errors.isApprove}</p>}

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="isPublish" className="form-label">
                        Publish <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        name="isPublish"
                        className="form-control"
                        id="isPublish"
                      >
                        <option value="" disabled>Select</option>
                        <option value="Publish">Publish</option>
                        <option value="Unpublish">Unpublish</option>

                      </Field>

                      {touched.isPublish && errors.isPublish && <p className='text-danger'>{errors.isPublish}</p>}

                    </div> */}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary me-1"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                    <Link
                      to="#"
                      onClick={() => setAddEmploye(false)}
                      className="btn btn-danger light ms-1"
                    >
                      Cancel
                    </Link>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default CoachOffcanvas;
