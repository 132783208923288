import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TextEditor from "../components/Common/TextEditor";
import { toast } from "react-toastify";
Yup.addMethod(Yup.string, "maxWords", function (maxWords, message) {
  return this.test("max-words", message, function (value) {
    const { path, createError } = this;
    // Split the value into words, filtering out spaces and empty strings
    const words = value ? value.split(/\s+/).filter(Boolean) : [];
    return (
      words.length <= maxWords ||
      createError({ path, message: `${message} Actual words: ${words.length}` })
    );
  });
});
const validationSchema = Yup.object().shape({
  codeValue: Yup.string().required("Code Value is required"),
  // .matches(/^[a-zA-Z0-9]*$/, 'Code Value must be alphanumeric'),
  profile: Yup.string().required("Profile is required"),
  // sportId: Yup.string()
  //     .required('Sport is required'),
  discount: Yup.number()
    .typeError("Discount must be a number")
    .required("Discount is required")
    .min(0, "Discount must be at least 0%")
    .max(100, "Discount must not exceed 100%"),
  subscriptionLimit: Yup.number()
    .typeError("Subscription Limit must be an integer")
    .integer("Subscription Limit must be an integer"), // Ensure value is an integer // Make it required if necessary
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date()
    .required("End Date is required")
    .min(Yup.ref("startDate"), "End Date cannot be before Start Date"),
  status: Yup.string().required("Status is required"),
  description: Yup.string()
    .required("Description is required")
    .maxWords(500, "Description must not exceed 500 words"),
});

const PromoCodeOffcanvas = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [addEmploye, setAddEmploye] = useState(false);
  const nav = useNavigate();
  const [promoCodeData, setPromoCodeData] = useState([]);
  // const [sports, setSports] = useState([]);
  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    },
  }));

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  const fetchPromoCodes = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PAYMENT_API_URI}/getallpromocodes`,
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setPromoCodeData(response.data.data); // Update promo code data state with fetched data
    } catch (error) {
      console.error("Error fetching promo codes:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const token = localStorage.getItem("token");

    try {
      const formData = {
        promocodeName: values.codeValue,
        // sportId: values.sportId,
        roleId: values.profile,
        discount: parseFloat(values.discount).toFixed(2),
        accessLimit: values.subscriptionLimit,
        startDate: values.startDate.toISOString().split("T")[0], // Format start date to YYYY-MM-DD
        endDate: values.endDate.toISOString().split("T")[0], // Format end date to YYYY-MM-DD
        isEnable: values.status === "Active" ? true : false,
        promocodeDescription: values.description,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_API_URI}/addPromocode`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
        }
      );
      setAddEmploye(false);
      console.log("Response:", response.data);
      props.onPromoCodeUpdated();

      fetchPromoCodes();
    } catch (error) {
      toast.error("Promo code Already Exist");
      console.error("Error:", error);
    }

    setSubmitting(false);
  };

  return (
    <Offcanvas
      show={addEmploye}
      onHide={() => setAddEmploye(false)}
      className="offcanvas-end customeoff"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">
          {props.Title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => setAddEmploye(false)}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <Formik
            initialValues={{
              codeValue: "",
              profile: "",
              // sportId: '',
              discount: "",
              subscriptionLimit: "",
              startDate: startDate, // Ensure these values are the correct format or null if not yet set
              endDate: endDate,
              status: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="codeValue" className="form-label">
                      Code Value <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      placeholder="Code Value"
                      className="form-control"
                      id="codeValue"
                      name="codeValue"
                    />
                    <ErrorMessage
                      name="codeValue"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="profile" className="form-label">
                      Profile <span className="text-danger">*</span>
                    </label>
                    <Field
                      as="select"
                      className="default-select form-control"
                      id="profile"
                      name="profile"
                    >
                      <option value="" disabled>
                        Select Profile
                      </option>
                      <option value="2">Athlete</option>

                      <option value="4">Club and Academy</option>
                    </Field>
                    <ErrorMessage
                      name="profile"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="discount" className="form-label">
                      Discount <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <Field
                        type="number"
                        placeholder="Discount (%)"
                        className="form-control"
                        id="discount"
                        name="discount"
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setFieldValue("discount", isNaN(value) ? "" : value);
                        }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">%</span>
                      </div>
                    </div>
                    <ErrorMessage
                      name="discount"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="startDate" className="form-label">
                      Start Date <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setFieldValue("startDate", date);
                      }}
                      name="startDate"
                      minDate={new Date()}
                      placeholderText="Start Date"
                    />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="endDate" className="form-label">
                      End Date{" "}
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => {
                        setendDate(date);
                        setFieldValue("endDate", date);
                      }}
                      name="endDate"
                      minDate={startDate}
                      placeholderText="Select End Date"
                    />

                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="subscriptionLimit" className="form-label">
                      Subscription Limit{" "}
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="subscriptionLimit"
                      name="subscriptionLimit"
                      placeholder="Subscription Limit"
                    />
                    <ErrorMessage
                      name="subscriptionLimit"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="status" className="form-label">
                      Status <span className="text-danger">*</span>
                    </label>
                    <Field
                      as="select"
                      className="default-select form-control"
                      id="status"
                      name="status"
                    >
                      <option value="" disabled>
                        Select Status
                      </option>
                      <option value="Active">Active</option>
                      <option value="Inactive">InActive</option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-xl-12 mb-3">
                    {/* <label className="form-label">Description</label> */}
                    <Field name="description">
                      {({ field, form }) => (
                        <div>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={field.value}
                            onChange={(event, editor) => {
                              form.setFieldValue(field.name, editor.getData());
                            }}
                          /> */}
                          <TextEditor
                            content={field.value}
                            setContent={(newContent) =>
                              form.setFieldValue(field.name, newContent)
                            }
                            title={'Description'}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary me-1"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <Link
                    to="#"
                    onClick={() => setAddEmploye(false)}
                    className="btn btn-danger light ms-1"
                  >
                    Cancel
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Offcanvas>
  );
});

export default PromoCodeOffcanvas;
