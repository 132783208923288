import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import NoImage from "../../../../images/no-image.jpg";
import TextEditor from "../../Common/TextEditor";

const Howitworks = () => {
  const [title, setTitle] = useState("");
  const [sectionContent, setSectionContent] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [banners, setBanners] = useState([]);
  const [localImages, setLocalImages] = useState([]);
  const [changedBanners, setChangedBanners] = useState([]);
  const [changeBanner, setChangeBanner] = useState([]);
  const [hideSection, setHideSection] = useState(true);

  useEffect(() => {
    fetchPageData();
  }, []);

  // Function to fetch page data
  const fetchPageData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/1`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const cmssections = response.data.data.cmssections;
      const section = cmssections.find((section) => section.id === 3);
      if (section) {
        setTitle(section.title || "");
        setSectionContent(section.description || "");
        setShortDescription(section.shortDescription || "");
      }

      const cmsgalleries = response.data.data.cmsgalleries;
      const bannerData = cmsgalleries.filter((item) => item.cmssectionId === 3);
      // setBanners(bannerData); // Set banners with fetched data
      console.log("bannerData", bannerData);

      const EventScheduleData = bannerData?.map((item) => {
        return {
          id: item.id,
          cmId: item.cmId,
          fileLocation: item.fileLocation,
          fileTitle: item?.fileTitle,
          fileDescription: item?.fileDescription,
        };
      });
      setBanners(EventScheduleData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Function to update page data
  const updatePageData = async (title, description, shortDescription) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const fetchedData = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/1`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const sectionToUpdate = fetchedData.data.data.cmssections.find(
        (section) => section.id === 3
      );

      if (!sectionToUpdate) {
        console.error("Section with id 3 not found");
        return;
      }

      sectionToUpdate.title = title;
      sectionToUpdate.shortDescription = shortDescription;

      // Filter and update only changed cmsgalleries
      const updatedBanners = fetchedData.data.data.cmsgalleries
        .filter((item) => item.cmssectionId === 3)
        .map((item) => {
          const changedBanner = changeBanner.find(
            (changed) => changed.id === item.id
          );
          if (changedBanner) {
            return {
              ...item,
              fileTitle: changedBanner.fileTitle || item.fileTitle,
              fileDescription:
                changedBanner.fileDescription || item.fileDescription,
            };
          }
          return item;
        })
        .filter((item) => {
          const originalItem = fetchedData.data.data.cmsgalleries.find(
            (original) => original.id === item.id
          );
          return (
            originalItem.fileTitle !== item.fileTitle ||
            originalItem.fileDescription !== item.fileDescription
          );
        });

      const updatedData = {
        ...fetchedData.data.data,
        cmssections: [sectionToUpdate],
        cmsgalleries: updatedBanners,
      };
      const data = { data: updatedData };
      console.log(data);
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updatepage`,
        data,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        swal("Update successful", { icon: "success" });
      } else {
        swal("Failed to update", { icon: "error" });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      swal("Failed to update", { icon: "error" });
    }
  };

  // Function to handle saving changes
  const handleSave = async () => {
    try {
      await updatePageData(title, sectionContent, shortDescription);
      await updateExactFiles(changedBanners);
    } catch (error) {
      console.error("Error saving changes:", error);
      swal("Failed to save changes", { icon: "error" });
    }
  };

  // Function to update exact files
  const updateExactFiles = async (changedBanners) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const formData = new FormData();
      formData.append(
        "cmsgalleries",
        JSON.stringify(
          changedBanners.map((banner) => ({
            id: banner.id,
            cmId: banner.cmId,
          }))
        )
      );
      changedBanners.forEach((banner) => {
        formData.append("fileLocation", banner.fileLocation);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updateexactfiles`,
        formData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Exact files updated successfully");
      } else {
        console.error("Failed to update exact files");
      }
    } catch (error) {
      console.error("Error updating exact files:", error);
    }
  };

  // Function to get image URL for display
  const getImageUrl = (banner, localImage) => {
    if (localImage) {
      return localImage;
    } else if (banner && banner.fileLocation) {
      return banner.fileLocation;
    } else {
      return NoImage;
    }
  };

  // Function to handle file selection
  const fileHandler = (event, index) => {
    const selectedFile = event.target.files[0];

    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);

    img.onload = () => {
      // if (
      //   img.width < 390 ||
      //   img.width > 400 ||
      //   img.height < 340 ||
      //   img.height > 350
      // ) {
      //   swal(
      //     "Invalid Image Dimensions",
      //     "Please select an image with width between 390 to 400 and height between 340 to 350 pixels.",
      //     "error"
      //   );
      // } else {
        const reader = new FileReader();

        reader.onload = () => {
          const newLocalImages = [...localImages];
          newLocalImages[index] = URL.createObjectURL(selectedFile);
          setLocalImages(newLocalImages);

          const newBanners = [...banners];
          newBanners[index] = {
            ...newBanners[index],
            fileLocation: selectedFile,
          };
          setBanners(newBanners);

          const newChangedBanners = [...changedBanners];
          const existingBanner = newChangedBanners.find(
            (banner) => banner.id === newBanners[index].id
          );
          if (existingBanner) {
            existingBanner.fileLocation = selectedFile;
          } else {
            const matchedBanner = newBanners[index];
            if (matchedBanner) {
              newChangedBanners.push({
                id: matchedBanner?.id,
                cmId: matchedBanner?.cmId,
                fileLocation: selectedFile,
              });
            }
          }
          setChangedBanners(newChangedBanners);
        };

        reader.readAsDataURL(selectedFile);
      // }
    };
  };

  // Function to handle title change in banners
  const handleTitleChange = (data, index) => {
    setBanners((prevBanners) => {
      const updatedBanners = [...prevBanners];
      updatedBanners[index] = {
        ...updatedBanners[index],
        fileTitle: data,
      };
      return updatedBanners;
    });

    // Update changedBanners state
    setChangeBanner((prevChangedBanners) => {
      const updatedChangedBanners = [...prevChangedBanners];
      const existingBanner = updatedChangedBanners.find(
        (banner) => banner?.id === banners[index]?.id
      );
      if (existingBanner) {
        existingBanner.fileTitle = data;
      } else {
        updatedChangedBanners.push({
          id: banners[index]?.id,
          cmId: banners[index]?.cmId,
          fileTitle: data,
          fileDescription: banners[index]?.fileDescription,
        });
      }
      return updatedChangedBanners;
    });
  };

  // Function to handle description change in banners
  const handleDescriptionChange = (data, index) => {
    setBanners((prevBanners) => {
      const updatedBanners = [...prevBanners];
      updatedBanners[index] = {
        ...updatedBanners[index],
        fileDescription: data,
      };
      return updatedBanners;
    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="h-auto">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-11">
                    <div className="content-title d-flex">
                      <h4 className="d-flex align-items-center">
                        Platform Benefits
                      </h4>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-dimensions-BANNER`}>
                            Image dimensions - 340-350px in height and 390-400px
                            in width.
                            <br></br>File Size should be under 300KB
                          </Tooltip>
                        }
                      >
                        <div className="d-flex  mx-2 align-items-center">
                          <i class="bi bi-question-circle"></i>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="content-title border-bot">
                      <Form.Check
                        type="switch"
                        id={`platformBenefits-section`}
                        checked={hideSection}
                        onChange={(event) =>
                          setHideSection(event.target.checked)
                        }
                      />
                    </div>
                  </div>
                </div>
                {hideSection && (
                  <>
                    <TextEditor
                      content={title}
                      setContent={setTitle}
                      title={"Title"}
                    />
                    <TextEditor
                      content={shortDescription}
                      setContent={setShortDescription}
                      title="Short Description"
                    />
                    <div className="card-body">
                      <div className="avatar-upload d-flex align-items-center justify-content-center flex-wrap">
                        {Array.from({ length: 6 }).map((_, index) => (
                          <React.Fragment key={index}>
                            <div className="col-xl-2 mb-3">
                              <div className="avatar-preview">
                                <div
                                  id={`imagePreview${index}`}
                                  style={{
                                    backgroundImage: `url(${getImageUrl(
                                      banners[index],
                                      localImages[index]
                                    )})`,
                                    width: "",
                                    height: "150px",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              </div>
                              <div className="change-btn d-flex align-items-center justify-content-center">
                                <input
                                  type="file"
                                  onChange={(e) => fileHandler(e, index)}
                                  id={`platformBenifits${index}`}
                                  className="form-control d-none"
                                  accept="image/*"
                                />
                                <label
                                  htmlFor={`platformBenifits${index}`}
                                  className="btn btn-secondary light ms-0"
                                >
                                  +
                                </label>
                              </div>
                            </div>
                            <div className="col-xl-5">
                              <TextEditor
                                content={banners[index]?.fileTitle || ""}
                                setContent={(data) =>
                                  handleTitleChange(data, index)
                                }
                                title="Image Heading"
                              />
                            </div>
                            <div className="col-xl-5">
                              <TextEditor
                                content={banners[index]?.fileDescription || ""}
                                setContent={(data) =>
                                  handleDescriptionChange(data, index)
                                }
                                title="Image Description"
                              />
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    <div className="content-title border-bot d-flex justify-content-center w-100">
                      <button
                        type="button"
                        className="btn btn-primary my-2 w-90px"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Howitworks;
