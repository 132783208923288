import { toast } from "react-toastify";
import UserManagerAPI from "./userManagerApi.service";

export default class AthleteProfile {
	fetchAthleteDetails = async (athleteId) => {
		try {
			const responseData = await UserManagerAPI.fetchAthleteById(athleteId);
			const profilePictureId = responseData?.galleryData?.[0]?.id;
            const profilePictureUrl =  responseData?.galleryData?.[0]?.fileLocation;
			// setGalleryId(fetchedGalleryId);

			// setProfile(responseData?.galleryData?.[0]?.fileLocation);

			const galleryData = responseData.galleryData;
			const headshot = galleryData.filter((item) =>
				item.fileType.startsWith("Headshot Image")
			);
			const sortedProfileImages = headshot.sort((a, b) => {
				const getFileNumber = (fileType) =>
					parseInt(fileType.replace("Headshot Image ", ""), 10);
				return getFileNumber(a.fileType) - getFileNumber(b.fileType);
			});
			const videosData = galleryData.filter(
				(item) => item.fileType === "Video"
			);
			// setVideos(videosData);

			if (sortedProfileImages.length > 0) {
				// setHeadshotImages(sortedProfileImages);
				// setAdditionalImages(sortedProfileImages);
			}

			if (videosData.length > 0) {
				// setVideo(videosData[0].fileLocation);
			}

			const sportsIds = responseData.athleteData.sportsId.split(",");
			const sportSelections = responseData.sportData
				.filter((sport) => sportsIds.includes(String(sport.id)))
				.map((sport) => ({
					value: sport.id,
					label: sport.sportName,
				}));

			const specialityIds = responseData?.specialityData;
			const specialitySelections = specialityIds.map((speciality) => ({
				value: speciality.id,
				label: speciality.specialityTitle,
			}));

			const mappedData = {
				id: responseData.userData.id || "",
				firstName: responseData.userData.firstName || "",
				lastName: responseData.userData.lastName || "",
				email: responseData.userData.email || "",
				age: responseData.athleteData.age || "",
				gender: responseData.athleteData.gender || "",
				city: responseData?.citiesData?.id || "",
				residentialState: responseData?.stateData?.id || "",
				school: responseData.athleteData.school || "",
				bio: responseData.athleteData.bio || "",
				grade: responseData.athleteData.grade || "",
				achievements: responseData.athleteData.achievements || "",
				parentFirstName: responseData.athleteData.parentFirstName || "",
				parentLastName: responseData.athleteData.parentLastName || "",
				parentEmail: responseData.athleteData.email || "",
				parentPhone: responseData.athleteData.parentPhone || "",
				parentConsent: responseData.athleteData.parentConsent || "",
				twitterLink: responseData.athleteData.twitterLink || "",
				instagramLink: responseData.athleteData.instagramLink || "",
				sportName: responseData.sportData.sportName || "",
				sportsId: sportSelections || "",
				subscriptionId: responseData.athleteData.subscriptionId || "",
				athleteSpecialty: specialitySelections || "",
				currentAcademie: responseData.athleteData.currentAcademie || "",
				subscriptionStatus:
					responseData?.subscriptionData?.subscribtionStatus || "",
				isApprove: responseData.athleteData.isApprove || "",
				isActive: responseData.athleteData.isActive || "",
				galleryId: responseData.athleteData.galleryId || "",
			};

            const result = {
                athleteData: mappedData,
                profilePictureId,
                profilePictureUrl,
                videosData,
                sortedProfileImages,
            }

            return result;
			// setSelectedSportIDs(responseData.athleteData.sportsId);
			// setFormData(mappedData); // Update form data
		} catch (error) {
			console.error("Error fetching athlete data:", error);
			toast.error("Failed to fetch athlete data!");
		}
	};
}
