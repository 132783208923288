import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import TextEditor from "../components/Common/TextEditor";

const SubscriptionOffcanvas = forwardRef((props, ref) => {
  const [addEmploye, setAddEmploye] = useState(false);
  const [sports, setSports] = useState([]);
  const [roleId, setRoleId] = useState("");

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    },
  }));

  useEffect(() => {
    const fetchSports = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_SPORTS_API_URI}/getallsports`,
          {
            headers: {
              authorization: token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setSports(response.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };

    fetchSports();
  }, []);
  Yup.addMethod(Yup.string, "maxWords", function (maxWords, message) {
    return this.test("max-words", message, function (value) {
      const { path, createError } = this;
      const wordCount = value ? value.split(/\s+/).filter(Boolean).length : 0;
      return wordCount <= maxWords || createError({ path, message });
    });
  });
  Yup.addMethod(Yup.number, "currency", function (message) {
    return this.test("currency", message, function (value) {
      // Check if it's a positive number with up to two decimal places
      const isValid = /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d{1,2})?$/.test(value);
      return isValid || this.createError({ path: this.path, message });
    });
  });

  const validationSchema = Yup.object().shape({
    subscriptionName: Yup.string().required("Subscription name is required"),
    description: Yup.string()
      .required("Description is required")
      .maxWords(500, "Description must not exceed 500 words"),
    subscriptionAmount: Yup.number()
      .required("Subscription amount is required")
      .positive("Amount must be a positive number")
      .currency("Invalid currency format. Example: 1234.56"),
    subscriptionLimit: Yup.string(),

    roleId: Yup.string().required("Profile  is required"),
    processingTax: Yup.number()
      .typeError("Processing Fee must be a decimal number")
      .required("Processing Fee is required")
      .min(0, "Tax must be greater than or equal to 0")
      .max(100, "Tax must be less than or equal to 100")
      .test(
        "decimal-places",
        "Tax must have up to 3 decimal places",
        (value) => value === undefined || /^\d+(\.\d{1,3})?$/.test(value)
      ),
    convenienceTax: Yup.number()
      .typeError("Convenience Fee must be a decimal number")
      .required("Convenience Fee is required")
      .min(0, "Tax must be greater than or equal to 0")
      .max(100, "Tax must be less than or equal to 100")
      .test(
        "decimal-places",
        "Tax must have up to 3 decimal places",
        (value) => value === undefined || /^\d+(\.\d{1,3})?$/.test(value)
      ),
    serviceTax: Yup.number()
      .typeError("Service Fee must be a decimal number")
      .required("Service Fee is required")
      .min(0, "Tax must be greater than or equal to 0")
      .max(100, "Tax must be less than or equal to 100")
      .test(
        "decimal-places",
        "Tax must have up to 3 decimal places",
        (value) => value === undefined || /^\d+(\.\d{1,3})?$/.test(value)
      ),

    subscriptionMonth: Yup.string()
      .required("subscriptionMonth is required")
      .matches(/^\d{2}$/, "subscriptionMonth must be exactly two digits"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form values:", values);
    const token = localStorage.getItem("token");
    const endpoint = `${process.env.REACT_APP_PAYMENT_API_URI}/addsubscription`;

    axios
      .post(endpoint, values, {
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("API response:", response.data);

        setAddEmploye(false);
        toast.success(response?.data?.message);
        props.onsubscriptionUpdated();
      })
      .catch((error) => {
        console.error("Error adding subscription:", error);
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const MAX_DESCRIPTION_WORDS = 1000;

  // Function to handle the change in CKEditor content
  const handleDescriptionChange = (description, form) => {
    // const description = editor.getData();
    const wordCount = description.trim().split(/\s+/).length;

    if (wordCount <= MAX_DESCRIPTION_WORDS) {
      form.setFieldValue("description", description);
      form.setFieldTouched("description", true);
    } else {
      toast.error("Maximum word limit reached for description");
      const trimmedDescription = description
        .split(/\s+/)
        .slice(0, MAX_DESCRIPTION_WORDS)
        .join(" ");
      form.setFieldValue("description", trimmedDescription);
    }
  };

  return (
    <>
      <Offcanvas
        show={addEmploye}
        onHide={() => setAddEmploye(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h5 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddEmploye(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <Formik
              initialValues={{
                subscriptionName: "",
                description: "",
                subscriptionAmount: "",
                subscriptionLimit: "",
                subscribtionStatus: "",

                roleId: "",
                processingTax: "",
                convenienceTax: "",
                serviceTax: "",
                subscriptionMonth: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur }) => (
                <Form>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="subscriptionName" className="form-label">
                        Subscription Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="subscriptionName"
                        name="subscriptionName"
                        placeholder="Subscription Name"
                      />
                      <ErrorMessage
                        name="subscriptionName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="roleId" className="form-label">
                        Profile <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="default-select form-control"
                        id="roleId"
                        name="roleId"
                      >
                        <option value="" disabled>
                          Select Profile
                        </option>
                        <option value="2">Athlete</option>

                        <option value="4">Club and Academy</option>
                      </Field>
                      <ErrorMessage
                        name="roleId"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="subscriptionLimit" className="form-label">
                        Subscription Limit
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="subscriptionLimit"
                        name="subscriptionLimit"
                        placeholder="Subscription Limit"
                      />
                      <ErrorMessage
                        name="subscriptionLimit"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="subscriptionMonth" className="form-label">
                        Duration (Month) <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="subscriptionMonth"
                        name="subscriptionMonth"
                        placeholder="subscriptionMonth"
                      />
                      <ErrorMessage
                        name="subscriptionMonth"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="subscriptionAmount">
                          Subscription Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <Field
                            type="text"
                            className="form-control"
                            id="subscriptionAmount"
                            name="subscriptionAmount"
                            placeholder="subscription amount"
                            onBlur={(e) => {
                              let value = e.target.value;
                              const numericValue = parseFloat(value);
                              if (!isNaN(numericValue)) {
                                value = numericValue.toFixed(2);
                              }
                              e.target.value = value;
                              setFieldValue("subscriptionAmount", value);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="subscriptionAmount"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="processingTax" className="form-label">
                        Processing Fee <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="processingTax"
                          name="processingTax"
                          placeholder="Processing Fee"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value !== "") {
                              const formattedValue =
                                parseFloat(value).toFixed(2);
                              setFieldValue("processingTax", formattedValue);
                            }
                            handleBlur(e);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="processingTax"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="convenienceTax" className="form-label">
                        Convenience Fee <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="convenienceTax"
                          name="convenienceTax"
                          placeholder="Convenience Fee"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value !== "") {
                              const formattedValue =
                                parseFloat(value).toFixed(2);
                              setFieldValue("convenienceTax", formattedValue);
                            }
                            handleBlur(e);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="convenienceTax"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                      <label htmlFor="serviceTax" className="form-label">
                        Service Fee <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="serviceTax"
                          name="serviceTax"
                          placeholder="Service Fee"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value !== "") {
                              const formattedValue =
                                parseFloat(value).toFixed(2);
                              setFieldValue("serviceTax", formattedValue);
                            }
                            handleBlur(e);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="serviceTax"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                                            <label htmlFor="subscribtionStatus" className="form-label">Subscription Status <span className="text-danger">*</span></label>
                                            <Field as="select" className="form-control" id="subscribtionStatus" name="subscribtionStatus" >
                                                <option value="" disabled>Select Status</option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                            </Field>
                                            <ErrorMessage name="subscribtionStatus" component="div" className="text-danger" />
                                        </div> */}
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      {/* <label className="form-label">
                        Description <span className="text-danger">*</span>
                      </label> */}
                      <Field name="description">
                        {({ field, form }) => (
                          <div>
                            {/* <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                onChange={(event, editor) => handleDescriptionChange(event, editor, form)}
                            /> */}
                            <TextEditor
                              content={field.value}
                              setContent={(newContent) =>
                                handleDescriptionChange(newContent, form)
                              }
                              title={"Description"}
                              required={true}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary me-1"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <Link
                    to={"#"}
                    onClick={() => setAddEmploye(false)}
                    className="btn btn-danger light ms-1"
                  >
                    Cancel
                  </Link>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default SubscriptionOffcanvas;
